module.exports = [
    {key: 'score', label: 'Взятие ворот', hasBar: true, filter: true, personalized: true},
    {key: 'possession', label: 'Владение', perc: true, statsLabel: 'Владение мячом'},
    {key: 'accurate_total', label: 'Точные передачи'},
    {key: 'shot', label: 'Неточный удар', personalized: true, fromSubtypes: true, statsLabel: 'Неточные удары'},
    {key: 'onTarget', label: 'Удар в створ', personalized: true, fromSubtypes: true, statsLabel: 'Удары в створ'},
    {key: 'post', label: 'Удар в каркас', personalized: true, fromSubtypes: true, statsLabel: 'Штанги / Перекладины'},
    {key: 'corner', label: 'Уголовой', hasBar: true, personalized: true, statsLabel: 'Угловые'},
    {key: 'foul', label: 'Нарушение правил', hasBar: true, personalized: true, statsLabel: 'Нарушения'},
    {key: 'offside', label: 'Оффсайд', hasBar: true, personalized: true, statsLabel: 'Оффсайды'},
    {key: 'yellow', label: 'Предупреждение', hasBar: true, personalized: true, statsLabel: 'Предупреждения'},
    {key: 'red', label: 'Удаление', hasBar: true, personalized: true, statsLabel: 'Удаления'},
    {key: 'assist', label: 'Ассист', personalized: true, filter: true}
]
