import React, { useState, useEffect, useContext, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import AppContext from '@ctx'
import { Button } from '@blueprintjs/core'
import { studyProgress } from '../Study/utils'
import Authorization from './Authorization'
import './style.css'

const InstallDialog = () => {
    const showPrompt = async () => {
        const promptEvent = window.deferredPrompt
        if(!promptEvent) {
            return
        }

        promptEvent.prompt()
        const result = await promptEvent.userChoice
        console.log('👍', 'userChoice', result)
        window.deferredPrompt = null
    }

    return  [
                <div className='tiny-dialog'>
                    <img className='icon' src={require('@icons/scoreboard.svg').default} />
                    <div className='description'>
                        для начала работы, приложение Amateum Stats должно быть добавлено на ваше устройство
                    </div>
                </div>,
                <div className='dialog-actions'>
                    <Button intent='primary' onClick={() => showPrompt()} icon='confirm' fill={true} text='Установить сейчас' large={true} style={{marginBottom: -20}} />
                </div>
            ]
}

const InstalledDialog = () => {
    return  <div className='tiny-dialog'>
                <img className='icon' src={require('@icons/shot.svg').default} />
                <div className='description'>
                    Установлено! Пожалуйста, найдите Amateum Stats в списке приложений на этом устройстве
                </div>
            </div>
}

const Home = props => {
    const ctx = useContext(AppContext)
    const history = useHistory()

    const showInstalled = () => {
        ctx.setCtx('dialog', {
            title: 'Порядок!',
            dissmissLocked: true,
            icon: 'confirm',
            body: <InstalledDialog />
        })
    }

    const progress = ctx.auth && ctx.auth.skills ? studyProgress(ctx.auth.skills) : 0

    useEffect(() => {
        if(!localStorage.getItem('amstat_installed')) {
            window.addEventListener('beforeinstallprompt', evt => {
                window.deferredPrompt = evt
                console.log('BEFORE IP Fired. Need to show dialog...')
                ctx.setCtx('dialog', {
                    title: 'Добро пожаловать',
                    dissmissLocked: true,
                    icon: 'compressed',
                    body: <InstallDialog />
                })
            })
        } else if(localStorage.getItem('amstat_installed') && !window.matchMedia('(display-mode: standalone)').matches) {
            showInstalled()
        }

        window.addEventListener('appinstalled', evt => {
            localStorage.setItem('amstat_installed', new Date().getTime())
            window.deferredPrompt = null
            showInstalled()
        })
    }, [ctx])

    return  <div className='screen home'>
                {!ctx.auth ? <Authorization /> : (
                    <div className={'menu-panel'+(progress < 80 ? ' reversed' : '')}>
                        <div className='menu-item' onClick={() => history.push('/matches')}>
                            <img src={require('@icons/live.svg').default} />
                            <b>Список матчей</b>
                        </div>
                        {/*<div className='menu-item' onClick={() => history.push('/study')}>
                            <img src={require('@icons/strategy.svg').default} />
                            <b>Обучение ({progress}%)</b>
                        </div>*/}
                    </div>
                )}
            </div>
}

export default Home
