import React, { useState, useEffect, useRef } from 'react'
import { Spinner, NonIdealState, Toaster, Icon, Tag, InputGroup, Label, ControlGroup, Button } from '@blueprintjs/core'
import { useHistory, useParams } from 'react-router-dom'
import CustomScrollbars from 'react-custom-scrollbars-2'
import axios from 'axios'
import moment from 'moment'

import '../Public/style.css'
import '../List/style.css'
import './style.css'

const base = process.env.NODE_ENV === 'production' ? 'https://rest.amateum.com/' : 'http://localhost:3003/'

const Item = ({ data, toaster }) => {
    return  <div className='screen-list_item match-item'>
                <div className='match-item_labels'>
                    {data.agent ? <Tag intent='warning'>{data.agent.name}</Tag> : null}
                </div>
                <div className='match-item_datetime'><Tag minimal={true}>{moment(data.match.date, 'YY-MM-DD').format('DD.MM')} {data.match.time}</Tag></div>
                <div className='match-item_teams'>
                    <div>
                        <span>{data.home.name}</span>
                        <div className='match-item_teams_emb'>
                            <img src={data.home.emblem} />
                        </div>
                    </div>
                    <div style={{padding: '0 6px'}}>
                        <Icon icon='cross' />
                    </div>
                    <div>
                        <div className='match-item_teams_emb'>
                            <img src={data.away.emblem} />
                        </div>
                        <span>{data.away.name}</span>
                    </div>
                </div>

                <div className='schedule-actions'>
                    <div>
                        <ControlGroup>
                            <Label>Поток данных:</Label>
                            <InputGroup value={`https://stat.amateum.com/datafeed/${data.stats._id}`} disabled={true} />
                            <Button
                                icon='clipboard'
                                text='Копировать'
                                onClick={() => {
                                    navigator.clipboard.writeText(`https://stat.amateum.com/datafeed/${data.stats._id}`)
                                    toaster.current.show({intent: 'primary', message: 'Ссылка скопирована в буфер обмена'})
                                }}
                            />
                        </ControlGroup>
                    </div>
                    <div>
                        <Button
                            text='LIVE'
                            icon='eye-open'
                            intent='danger'
                            onClick={() => window.open(`https://stat.amateum.com/public/${data.stats._id}`)}
                        />
                    </div>
                </div>
            </div>
}

const Schedule = () => {
    const [list, setList] = useState(null)

    const { alias } = useParams()
    const history = useHistory()

    const toaster = useRef(null)

    useEffect(() => {
        axios.get(`${base}statschedule${alias !== 'all' ? '?filter[alias]='+alias : ''}`)
            .then(resp => {
                setList(resp.data
                    .filter(i => i.match)
                    .sort((a, b) => {
                        const am = moment(a.match.date+' '+a.match.time, 'YY-MM-DD HH:mm').unix()
                        const bm = moment(b.match.date+' '+b.match.time, 'YY-MM-DD HH:mm').unix()
                        return am - bm
                    })
                )
            })
    }, [])

    return  <div id='public'>
                <Toaster ref={toaster} />

                {!list ? (
                    <div>
                        <NonIdealState
                            style={{height: 200}}
                            children={(<Spinner size={80} />)}
                            description='загрузка...'
                        />
                    </div>
                ) : (
                    <div>
                        <CustomScrollbars autoHeight autoHeightMin='calc(100vh - 50px)'>
                        {list.map(i => (
                            <Item key={i._id} data={i} toaster={toaster} />
                        ))}
                        </CustomScrollbars>
                    </div>
                )}
            </div>
}

export default Schedule
