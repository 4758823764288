import React, { useState, useRef, useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Toaster, Position, Dialog } from '@blueprintjs/core'
import * as Sentry from "@sentry/react";
import { AppProvider } from './appContext'
import Match from '@components/Match'
import List from '@components/List'
import Home from '@components/Home'
import Study from '@components/Study'
import Heading from '@components/Heading'
import Schedule from '@components/Schedule'

import UAParser from 'ua-parser-js'

import axios from 'axios'

import { VERSION } from './env'

import './introjs.min.css'
import './style.css'
import './media_375.css'
import './media_360.css'
import './app.scss'

const authData = localStorage.getItem('amstat_auth') ? JSON.parse(localStorage.getItem('amstat_auth')) : null
const uaparser = new UAParser()

if(authData) {
    Sentry.setContext('context', {
        app_version: VERSION,
        statist: authData.name || 'Unauthorized'
    })
}

//TODO replace with actual endpoints
//const base = process.env.NODE_ENV === 'production' ? 'https://stats.amateum.com/' : 'http://localhost:1100/'
const base = localStorage.getItem('amstat_endpoint') || 'https://stats.amateum.com/'

const fillDevice = () => {
    return {
        ...uaparser.getResult(),
        vibr: pattern => {
            if(typeof(navigator.vibrate) !== 'undefined') {
                navigator.vibrate(pattern)
            }
        }
    }
}

const App = () => {
    const [context, setContext] = useState({
        rest: base,
        toaster: useRef(null),
        dialog: null,
        logWorker: null,
        installed: window.matchMedia('(display-mode: standalone)').matches || window.location.search.includes('sa=true'), //force standalone condition
        auth:  authData ? {...authData, skills: authData.skills || []} : null,
        device: null
    })

    useEffect(() => {
        try {
            const logWorker = new Worker('/workers/log-worker.js')
            logWorker.onmessage = msg => {
                const { action, data, target } = msg.data || {}
                switch (action) {
                    case 'backup':
                        axios.post(`${context.rest}stat_backup/${target}`, data)
                            .then(resp => {
                                if(resp.data && !resp.data.error) {
                                    const stamp = new Date().getTime()
                                    localStorage.setItem('statLastBackup', stamp)
                                    logWorker.postMessage({type: 'backuped', stamp: stamp})
                                }
                            })
                        break
                }
            }

            logWorker.postMessage({type: 'setBackupStamp', stamp: localStorage.getItem('statLastBackup')})
            setContext({...context, logWorker: logWorker, device: fillDevice()})
            console.log('Log worker inited')
        } catch(e) {
            context.toaster.current.show({intent: 'danger', message: 'Ошибка регистрации logWorker'})
        }
    }, [])

    return  <AppProvider value={{...context, setCtx: (k, v) => setContext({...context, [k]: v}), batchSetCtx: obj => setContext(obj)}}>
                <Toaster position={Position.TOP} ref={context.toaster}></Toaster>

                <Dialog isOpen={context.dialog != null}>
                    {context.dialog ? [
                        <div className='bp3-dialog-header'>
                            <span className={'bp3-icon-large bp3-icon-'+context.dialog.icon}></span>
                            <h4 className='bp3-heading'>{context.dialog.title}</h4>
                            {!context.dialog.dissmissLocked ? <button aria-label='Close' className='bp3-dialog-close-button bp3-button bp3-minimal bp3-icon-cross' onClick={() => setContext({...context, dialog: null})}></button> : null}
                        </div>,
                        <div className='bp3-dialog-body'>{context.dialog.body}</div>
                    ] : null}
                </Dialog>

                <div style={context.dialog ? {filter: 'blur(2.5px)'} : {}}>
                    <Router>
                        <Switch>
                            <Route path='/schedule/:alias'>
                                <Schedule />
                            </Route>
                            <Route path='/matches/:matchId'>
                                <Match />
                            </Route>
                            <Route path='/matches'>
                                <Heading />
                                <List />
                            </Route>
                            <Route path='/'>
                                <Heading />
                                <Home />
                            </Route>
                        </Switch>
                    </Router>
                </div>
            </AppProvider>
}

export default App
