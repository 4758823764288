import React, { useState, useContext } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Button } from '@blueprintjs/core'
import AppContext from '@ctx'
import { VERSION } from '../../env'

import './style.css'

const Heading = () => {
    const ctx = useContext(AppContext)
    const routes = Object.keys(useParams())
    const history = useHistory()

    return  ctx && ctx.auth ? <div className='heading'>
                <div>
                    <img src={require('@icons/brand.png').default} />
                    {history.location.pathname !== '/' ? <Button text='назад' onClick={() => history.push('/'+(routes[0] || ''))} intent='default' icon='chevron-left' style={{marginLeft: 12}} /> : null}
                </div>
                <span>v{VERSION}</span>
                <div>{ctx.auth.name}</div>
            </div> : null
}

export default Heading
