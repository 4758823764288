import axios from 'axios'
import { get, set, entries, update, clear, createStore } from 'idb-keyval'
import { convertTime } from './utils'
const types = require('./displayTypes')

const keys = types.map(t => t.key)

//const ENDPOINT = process.env.NODE_ENV === 'production' ? 'https://stats.amateum.com/' : 'http://localhost:1100/'
const ENDPOINT = localStorage.getItem('amstat_endpoint')

const VIABLE_POSS_DURATION = 3
const VIABLE_POSS_KEYEVS = 1
const KEYEVS_RESET_TRIGGERS = ['shot', 'corner', 'out', 'foul', 'offside', 'score', 'shot', 'yellow', 'red']
const IGNORE_RECENT_EVS = ['accurate_total']
const ACCURACY_SOURCES = ['pass_short', 'pass_medium', 'pass_far']
const ACCURACY_IGNORE = ['possession']
const directions = ['fw', 'fwleft', 'fwright', 'left', 'right', 'bck', 'bckleft', 'bckright']
const distances = ['short', 'medium', 'far']

const defaultDirections = ['home', 'away'].reduce((acc, t) => {
    for(let d of directions) {
        for(let _d of distances) {
            acc[t+'_'+d+'_'+_d] = 0
        }
    }
    return acc
}, {})

let storeInstance
let posData = {
    current: null,
    prev: null
}

const updateRecent = async (obj, filterStamp=null) => {
    if(!IGNORE_RECENT_EVS.includes(obj.type)) {
        const _rec = await get('recent', storeInstance)
        let rec = JSON.parse(_rec)
        if(!filterStamp) {
            rec.unshift(obj)
            await set('recent', JSON.stringify(rec), storeInstance)
        } else {
            const filtered = rec.filter(e => e.stamp !== filterStamp).sort((a, b) => (b.stamp-a.stamp))
            await set('recent', JSON.stringify(filtered), storeInstance)
        }
    }

    return true
}

const getPrevEvent = async () => {
    const _rec = await get('recent', storeInstance)
    const rec = JSON.parse(_rec)
    return rec[1] || null
}

const isAccuratePass = async input => {
    const { type, side, stamp } = input
    const prev = await getPrevEvent()
    if(prev) {
        if(ACCURACY_SOURCES.includes(type) && !ACCURACY_IGNORE.includes(prev.type) && side === prev.side) {
            //TODO: add time offset exception
            if(prev.type.includes('pass')) {
                return prev.type.split('_')[1]
            } else {
                return 'unclassified'
            }
        }
    } else {
        return null
    }
}

const collectData = async (storeInstance) => {
    const data = await entries(storeInstance)
    return Object.keys(data).reduce((acc, idx) => {
        const entry = data[idx]
        if(entry[0].includes('.')) {
            const k = entry[0].split('.')
            if(!acc[k[0]]) {
                acc[k[0]] = {}
            }
            acc[k[0]][k[1]] = entry[1]
        } else {
            acc[entry[0]] = entry[1] && entry[1][0] === '[' ? JSON.parse(entry[1]) : entry[1]
        }

        return acc
    }, {})
}

const initEmptyStore = async (storedMarkers, backupedStats) => {
    console.log(keys)
    for(let side of ['home', 'away']) {
        for(let key of keys) {
            await set(`${side}.${key}`, backupedStats[side] ? backupedStats[side][key] || 0 : 0, storeInstance)
        }
    }

    await set('recent', JSON.stringify(storedMarkers || []), storeInstance)
    await set('home.score', backupedStats.home ? backupedStats.home.score : 0, storeInstance)
    await set('away.score', backupedStats.home ? backupedStats.home.score : 0, storeInstance)
    await set('period', 'null', storeInstance)
    await set('closedPeriods', JSON.stringify([]), storeInstance)
    await set('time', 0, storeInstance)
    await set('mirror', false, storeInstance)
    await set('directions', defaultDirections, storeInstance)

    return true
}

export const resetMatch = async matchId => {
    return new Promise(async (resolve) => {
        storeInstance = await createStore(matchId, 'keyval')
        initEmptyStore()
        setTimeout(async () => {
            const data = await collectData(storeInstance)
            resolve(data)
        }, 1000)
    })
}

export const storeTime = async () => {
    await update(`time`, (cur) => (parseInt(cur) || 0) + 1, storeInstance)
    const time = await get('time', storeInstance)
    return time
}

export const collectStats = async () => {
    const data = await collectData(storeInstance)
    return data
}

export const initStore = async (matchId, initStoredMatch, applyStoredState, logWorker, storedMarkers, backupedStats) => {
    storeInstance = await createStore(matchId, 'keyval')
    const time = await get('time', storeInstance)
    let data

    if(!time || !matchId) {
        await initEmptyStore(storedMarkers, backupedStats)
        data = await collectData(storeInstance)
        return data
    } else {
        initStoredMatch({
            body: 'Матч уже обрабатывался на этом устройстве. Последняя метка времени: '+convertTime(time),
            cancelButtonText: 'К списку матчей',
            confirmButtonText: 'Продолжить с отметки '+convertTime(time),
            intent: 'primary',
            icon: 'history',
            className: 'notification',
            onCancel: async () => {
                // initStoredMatch(null)
                // await clear(storeInstance)
                // await initEmptyStore()
                // data = await collectData(storeInstance)
                // applyStoredState(data, true)
                // logWorker.postMessage({type: 'reseted', matchId: matchId})
                // return data
                window.location.replace('/matches')
            },
            onConfirm: async () => {
                initStoredMatch(null)
                data = await collectData(storeInstance)
                applyStoredState(data)
                return data
            }
        })
    }
}

const handleTransition = async (e, pos=false) => {
    if(pos) {
        switch (true) {
            case !posData.current:
                posData = {
                    ...posData,
                    current: {
                        side: e.side,
                        duration: 1,
                        keyEvs: 0
                    }
                }
                break
            case posData.current.side !== e.side:
                posData = {
                    prev: Object.assign({}, posData.current),
                    current: {
                        side: e.side,
                        duration: 1,
                        keyEvs: 0
                    }
                }
                break
            case posData.current.side === e.side:
                const _posData = {
                    ...posData,
                    current: {
                        ...posData.current,
                        duration: posData.current.duration+1
                    }
                }

                switch (true) {
                    case _posData.prev && (posData.prev.keyEvs < VIABLE_POSS_KEYEVS || posData.prev.duration < VIABLE_POSS_DURATION):
                        _posData.prev = null
                        break
                    case _posData.current && _posData.prev && !_posData.prev.transited && (_posData.current.keyEvs > 0) && !(_posData.current.duration < VIABLE_POSS_DURATION) && !(_posData.prev.duration < VIABLE_POSS_DURATION) && !(_posData.prev.keyEvs < VIABLE_POSS_KEYEVS):
                        await update(`${e.side}.transition`, (cur) => (parseInt(cur) || 0) + 1, storeInstance)
                        _posData.prev.transited = true
                        break
                }

                posData = _posData
                break
        }
    } else {
        if(posData.current) {
            posData = {
                ...posData,
                current: {
                    ...posData.current,
                    keyEvs: KEYEVS_RESET_TRIGGERS.includes[e.type] ? 0 : posData.current.keyEvs+1
                }
            }
        }
    }
}

export const updateEvent = async input => {
    const _rec = await get('recent', storeInstance)
    let rec = JSON.parse(_rec)
    const matchedMarker = rec.find(e => e.stamp === input.stamp)

    if(matchedMarker && matchedMarker.markerId) {
        try {
            axios.put(`${ENDPOINT}markers/${matchedMarker.markerId}`, {
                type: input.type || matchedMarker.type,
                playerId: input.person && input.person.player ? input.person.player._id : matchedMarker.playerId,
                needRefinement: input.person ? input.person.needRefinement : matchedMarker.needRefinement
            }, {
                headers: {
                    Authorization: localStorage.getItem('amstat_tkn')
                }
            })
        } catch(e) {
            console.log('Failed updating existed marker')
        }
    }

    const upd = rec.map(e => input.stamp === e.stamp ? ({...e, person: input.person || e.person, type: input.type || e.type, markerId: input.markerId || e.markerId || null}) : {...e})
    await set('recent', JSON.stringify(upd), storeInstance)
    const data = await collectData(storeInstance)
    return data
}

export const storeEvent = async input => {
    const { type, side, stamp, value, setval, direction, transferTargetId, markerId } = input
    if(!setval) {
        await update(side ? `${side}.${type}` : type, (cur) => (parseInt(cur) || 0) + value, storeInstance)
    } else {
        await set(type, value, storeInstance)
    }

    if(markerId && transferTargetId) {
        try {
            axios.put(`${ENDPOINT}markers/${markerId}`, {
                clubId: transferTargetId
            }, {
                headers: {
                    Authorization: localStorage.getItem('amstat_tkn')
                }
            })
        } catch(e) {
            console.log('Failed updating existed marker')
        }
    }

    if(type !== 'possession' && type !== 'time' && type !== 'period' && type !== 'mirror') {
        await updateRecent(input, value < 0 ? input.stamp : null)
        handleTransition(input)
        const accurateMarker = await isAccuratePass(input)

        if(direction) {
            await update(`directions.${side}_${direction}_${type.split('_')[1]}`, (cur) => (parseInt(cur) || 0) + 1, storeInstance)
        }

        if(type === 'score') {
            await update(`${side}.onTarget`, (cur) => (parseInt(cur) || 0) + 1, storeInstance)
        }

        if(accurateMarker) {
            await update(`${side}.accurate_total`, (cur) => (parseInt(cur) || 0) + value, storeInstance)
            if(accurateMarker !== 'unclassified') {
                await update(`${side}.accurate_${accurateMarker}`, (cur) => (parseInt(cur) || 0) + value, storeInstance)
            }
        }
    } else if(type === 'possession') {
        handleTransition(input, true)
    }

    if(type === 'period') {
        await set('period', value, storeInstance)
        if(value && value.includes('f')) {
            await update('mirror', (cur) => !cur, storeInstance)
            await update('closedPeriods', (cur) => JSON.stringify(JSON.parse(cur).concat([value.replace('f', '')])), storeInstance)
        }
    }

    const data = await collectData(storeInstance)
    return data
}
