import React, { useState, useEffect, useContext, useRef } from 'react'
import { Button, Alert } from '@blueprintjs/core'
import { Steps, Hints } from 'intro.js-react'
import CustomScrollbars from 'react-custom-scrollbars-2'
import { useParams, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import AppContext from '@ctx'
import Control from './Control'
import Bars from './Bars'
import Stats from './Stats'
import Recent from './Recent'
import Top from './Top'
import './style.css'
import './match.scss'
import { storeEvent, updateEvent, storeTime, collectStats } from './store'
import { splitTime, normalizeStatsKeys, createSnapshot, prepareRosters } from './utils'
import axios from 'axios'
import displayTypes from './displayTypes'

//import rosters from './mockup'

const panels = [
    {label: 'Репортаж', value: 'controls'},
    {label: 'События', value: 'recent'},
    {label: 'Сводка', value: 'stats'}
]

const DEFAULT_FREQ = 10
const AUTO_STOP_POSSESSION = ['offside', 'foul', 'score', 'yellow', 'red', 'shot'] //TODO new missed shot key
//const ENDPOINT = process.env.NODE_ENV === 'production' ? 'https://stats.amateum.com/' : 'http://localhost:1100/'
const ENDPOINT = localStorage.getItem('amstat_endpoint')
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

const Match = ({ study, incStudyStep }) => {
    const [stats, setStats] = useState(null)
    const [time, setTime] = useState(0)
    const [panel, setPanel] = useState('controls')
    const [bubbles, setBubbles] = useState([])
    const [alert, setAlert] = useState(null)
    const [matchData, setMatchData] = useState(null)
    const [autoStopTrigger, setAutoStopTrigger] = useState(null)
    const [storedMarkers, setStoredMarkers] = useState(null)
    const [rosters, setRosters] = useState({
        home: {
            main: [],
            subs: []
        },
        away: {
            main: [],
            subs: []
        }
    })
    const [backupedStats, setBackupedStats] = useState(null)
    const ticker = useRef(false)

    const ctx = useContext(AppContext)
    const { matchId, area } = useParams()
    const history = useHistory()

    const activeIdx = panels.findIndex(p => p.value === panel)

    const sentStatsStamp = useRef(new Date().getTime())
    const timeRef = useRef(0)

    const handleEvent = async e => {
        //console.log('EVENT FIRED', e)
        let overPlayed = false
        let markerId = null

        const canonicalTime = e.fixedTime || timeRef.current

        if(e.type !== 'possession') {
            if(AUTO_STOP_POSSESSION.includes(e.type)) {
                setAutoStopTrigger(true)
            }

            if(stats.period && !stats.period.includes('f')) {
                const periodNum = parseInt(stats.period)
                if(!isNaN(periodNum)) {
                    const edge = periodNum*matchData.entity.duration*60
                    if(canonicalTime > edge) {
                        overPlayed = (periodNum*matchData.entity.duration < 10 ? '0' : '')+(periodNum*matchData.entity.duration)+'+'+Math.ceil((canonicalTime - edge)/60)
                    }
                }
            }

            setBubbles(bubbles.filter(b => new Date().getTime() - b.stamp < 10000).concat({...e, pos: Math.floor(Math.random() * (5 - 1 + 1)) + 1}))
            const {label, value, ...edata} = e
            const matchedType = displayTypes.find(t => t.key === e.type)

            if(matchedType && matchedType.personalized) {
                const splitted = splitTime(canonicalTime)
                const markerBody = {
                    feedId: matchId,
                    matchId: matchData.entity.matchId,
                    clientTimestamp: e.stamp,
                    clubId: matchData[e.side]._id,
                    playerId: e.person && e.person.player ? e.person.player._id : null,
                    type: e.type,
                    matchMinutes: splitted.mins,
                    matchSeconds: splitted.secs,
                    isAdditionalTime: overPlayed !== false
                }

                try {
                    const marker = await axios.post(`${ENDPOINT}markers`, markerBody, {
                        headers: {
                            Authorization: localStorage.getItem('amstat_tkn')
                        }
                    })

                    if(marker.data && marker.data.markerId) {
                        markerId = marker.data.markerId
                        //check if there stashed markers
                        ctx.logWorker.postMessage({type: 'retrieveMarkers', feedId: markerBody.feedId})
                        ctx.logWorker.onmessage = async msg => {
                            if(msg.data && msg.data.action === 'backupedMarkers') {
                                if(msg.data.data && msg.data.data.length) {
                                    handleBackupMarkers(msg.data.data)
                                }
                            }
                        }
                    }
                } catch(e) {
                    console.log('Failed store event')
                    ctx.logWorker.postMessage({type: 'backup_marker', body: markerBody})
                }
            }
        }

        const _stats = await storeEvent({...e, matchTime: canonicalTime, overPlayed: overPlayed, markerId: markerId})
        ctx.logWorker.postMessage({type: 'backup_stats', body: normalizeStatsKeys({home: _stats.home, away: _stats.away}), feedId: matchId})
        setStats(_stats)
    }

    const sendStats = async () => {
        const now = new Date().getTime()
        const freq = matchData ? matchData.entity.freq || DEFAULT_FREQ : DEFAULT_FREQ
        if(matchId && (now - sentStatsStamp.current) > (freq*1000)) {
            sentStatsStamp.current = now
            const stats = await collectStats()
            const { recent, ...data } = stats
            const snapshot = createSnapshot(stats, timeRef.current)

            axios.put(`${ENDPOINT}feeds/${matchId}`, snapshot, {
                headers: {
                    Authorization: localStorage.getItem('amstat_tkn')
                }
            })
            .then(saved => {
                return
            })
        }
    }

    useEffect(() => {
        if((!ctx.installed || !ctx.auth) && !window.location.href.includes('000')) {
            history.push('/')
        } else {
            const { matchData } = ctx
            if(matchData) {
                const updMatchData = {
                    entry: matchData._id,
                    home: matchData.match.home,
                    away: matchData.match.away,
                    entity: {...matchData, duration: 45}
                }

                setMatchData(updMatchData)

                //try retrieve markers backup:
                ctx.logWorker.postMessage({type: 'retrieveStats', feedId: matchData._id})
                ctx.logWorker.postMessage({type: 'retrieveMarkers', feedId: matchData._id})
                ctx.logWorker.onmessage = async msg => {
                    if(msg.data && msg.data.action === 'backupedMarkers') {
                        if(msg.data.data && msg.data.data.length) {
                            handleBackupMarkers(msg.data.data)
                        }
                    } else if(msg.data && msg.data.action === 'backupedStats') {
                        if(msg.data.data) {
                            setBackupedStats(msg.data.data)
                        }
                    }
                }
            } else {
                history.push('/matches')
            }
        }
    }, [ctx.matchData])

    useEffect(() => {
        if(!storedMarkers && backupedStats && matchData && matchData.entity) {
            axios.get(`${ENDPOINT}feedmarkers/${matchId}`, {
                    headers: {
                        Authorization: localStorage.getItem('amstat_tkn')
                    }
                })
                .then(async markers => {
                    console.log('Got', markers.data.length, 'markers')
                    const withSides = markers.data.map(e => ({
                        ...e,
                        side: e.clubId === matchData.home._id ? 'home' : 'away'
                    }))

                    setStoredMarkers(withSides)
                })
                .catch(e => {
                    console.log(e)
                    setStoredMarkers([])
                })

            axios.get(`${ENDPOINT}rosters/${matchData.entity.matchId}`, {
                headers: {
                    Authorization: localStorage.getItem('amstat_tkn')
                }
            })
            .then(rosters => {
                setRosters(rosters.data)
            })
        }
    }, [backupedStats])

    useEffect(() => {
        let tickerWorker = new Worker('/workers/ticker.js')
        tickerWorker.onmessage = async msg => {
            if(msg.data && msg.data.action === 'tick') {
                if(ticker.current) {
                    const time = await storeTime()
                    timeRef.current = time
                    setTime(time)
                    sendStats()
                }
            }
        }

        return () => {
            tickerWorker = null
        }
    }, [])

    useEffect(() => {
        if(time > 0 && time%60 === 0) {
            if(!rosters || !rosters.rosters || (rosters.rosters.home && rosters.rosters.home.main && rosters.rosters.home.main.length < 9) || (rosters.rosters.away && rosters.rosters.away.main && rosters.rosters.away.main.length < 9)) {
                if(matchData.entity && matchData.entity.match && !matchData.entity.match.ignoreAPI) {
                    console.log('Rosters are invalid. Retrieving...')
                    axios.get(`${ENDPOINT}rosters/${matchData.entity.matchId}`, {
                        headers: {
                            Authorization: localStorage.getItem('amstat_tkn')
                        }
                    })
                    .then(rosters => {
                        setRosters(rosters.data)
                    })
                }
            }
        }
    }, [time])

    const handleBackupMarkers = async markers => {
        for(let marker of markers) {
            try {
                const resp = await axios.post(`${ENDPOINT}markers`, marker, {
                    headers: {
                        Authorization: localStorage.getItem('amstat_tkn')
                    }
                })

                if(resp.data) {
                    ctx.logWorker.postMessage({type: 'filterBackupedMarker', feedId: marker.feedId, stamp: marker.clientTimestamp})
                    updateEvent({stamp: marker.clientTimestamp, markerId: resp.data.markerId})
                    await sleep(3000)
                }
            } catch(e) {
                console.log('Still avoiding error')
            }
        }
    }

    const resetRemoteLogs = () => {
        if(matchId) {
            axios.put(`${ctx.rest}stats/${matchId}`, {data: null, state: null, updated: null})
            axios.delete(`${ctx.rest}stat_backup/${matchId}`)
        }
    }

    const upgradeNormalTime = async (arr) => {
        const time = arr.reduce((acc, p) => {
            acc += matchData.entity[p.includes('E') ? 'etDuration' : 'duration']*60
            return acc
        }, 0)

        const _s = await storeEvent({type: 'time', side: null, stamp: null, value: time, setval: true})
        timeRef.current = time
        setTime(time)
    }

    const onPeriodLaunched = async p => {
        if(p) {
            const stats = await storeEvent({type: 'period', side: null, value: p, stamp: new Date().getTime()})
            setStats(stats)
            ticker.current = (!p.includes('f'))
            if(p.includes('f')) {
                upgradeNormalTime(stats.closedPeriods)
            }
            //sendStatsAndState(stats, p)
        }
    }

    const onUpdatedRosters = _rosters => {
        setRosters({...rosters, rosters: {..._rosters}})
        axios.put(`${ENDPOINT}matches/${matchData.entity.matchId}`, {
            rosters: prepareRosters(_rosters)
        }, {
            headers: {
                Authorization: localStorage.getItem('amstat_tkn')
            }
        })
    }

    const stepsRef = useRef(null)

    return  <div className='match'>
                {matchData ? (
                    <Helmet>
                        <link rel='preload' as='image' href={`${matchData.externalBase}img/${matchData.home.logoId}.png`} />
                        <link rel='preload' as='image' href={`${matchData.externalBase}img/${matchData.away.logoId}.png`} />
                    </Helmet>
                ) : null}
                <Top
                    period={stats ? stats.period || null : '-'}
                    time={time}
                    onPeriodLaunched={onPeriodLaunched}
                    matchData={matchData}
                    score={{
                        home: stats && stats.home ? stats.home.score : '-',
                        away: stats && stats.away ? stats.away.score : '-'
                    }}
                    mirror={stats ? stats.mirror || false : false}
                    setMatchStats={stats => setStats(stats)}
                    onSidesReverted={async v => {
                        const _s = await storeEvent({type: 'mirror', value: v, setval: true, side: null, stamp: null})
                        setStats(_s)
                    }}
                />
                <div className='panels' id='panels'>
                    <div className='panels-nav'>
                        {panels.map((p, idx) => (
                            <Button key={`tab_${idx}`} id={p.value+'PanelBtn'} text={p.label} className={p.value === panel ? 'active' : ''} large={true} minimal={true} intent='primary' onClick={() => setPanel(p.value)} />
                        ))}
                    </div>
                    <CustomScrollbars
                        autoHeight
                        autoHeightMin='100%'
                        renderView={props => (
                            <div {...props} style={{ ...props.style, overflowX: 'hidden' }} />
                        )}
                    >
                        <div className='panels-row' style={{transform: `translate(${(0 - (activeIdx*100))}vw, 0)`}}>
                            <Bars
                                ticker={ticker.current}
                                onEvent={handleEvent}
                                mirror={stats ? stats.mirror : false}
                                hasOffsides={true}
                                rosters={rosters}
                                time={time}
                                onUpdatedRosters={onUpdatedRosters}
                                ignoreApi={matchData && matchData.entity ? matchData.entity.match.ignoreAPI || false : false}
                            />
                            <Recent
                                data={stats ? stats.recent : []}
                                throwStats={stats => setStats(stats)}
                                matchData={matchData}
                                rosters={rosters}
                                mirror={stats ? stats.mirror : false}
                                ignoreApi={matchData && matchData.entity ? matchData.entity.match.ignoreAPI || false : false}
                            />
                            <Stats
                                data={stats}
                                hasOffsides={matchData && matchData.entity && matchData.entity.offsides}
                                passesMode={matchData && matchData.entity ? matchData.entity.passesMode : null}
                                mirror={stats ? stats.mirror : false}
                                ignoreApi={matchData && matchData.entity ? matchData.entity.match.ignoreAPI || false : false}
                            />
                        </div>
                    </CustomScrollbars>
                </div>

                {bubbles.map(b => (
                    <div className='bubble-event' key={b.stamp} style={{left: b.pos*10+'%'}}>
                        <img src={matchData[b.side].emblem || `${matchData.entity.externalBase}img/${matchData[b.side].logoId}.png` } />
                        <span>{b.label}</span>
                    </div>
                ))}

                <Control
                    mirror={stats ? stats.mirror : false}
                    onEvent={handleEvent}
                    throwStats={obj => setStats(obj)}
                    matchId={matchId}
                    matchData={matchData}
                    storedMarkers={storedMarkers}
                    backupedStats={backupedStats}
                    initStoredMatch={alert => setAlert(alert)}
                    applyStoredState={(stats, reseted=false) => {
                        setStats(stats)
                        setTime(stats.time)
                        timeRef.current = stats.time
                        if(stats.period && !stats.period.includes('f')) {
                            ticker.current = true
                        }
                    }}
                    ticker={ticker.current}
                    accMode={true}
                    lockedPasses={matchData && matchData.entity && matchData.entity.passesMode === 'none'}
                    resetPossessionCallback={null}
                    autoStopTrigger={autoStopTrigger}
                    resetAutoStopTrigger={() => setAutoStopTrigger(null)}
                />

                {alert ? (
                    <Alert {...alert} isOpen={true}>
                        {alert.body}
                    </Alert>
                ) : null}
            </div>
}

export default Match
