export const demoMatch = {
    _id: 'demo',
    entity: {
        periods: 2,
        duration: 15,
        etDuration: 5,
        pureTime: false
    },
    homeClub: {
        name: 'Дортмунд',
        emblem: require('@icons/dortmund.svg').default
    },
    awayClub: {
        name: 'Париж',
        emblem: require('@icons/paris.svg').default
    }
}

const items = [
    {
        path: 'ui',
        label: 'Интерфейс матча',
        weight: 10
    },
    {
        path: 'timing',
        label: 'Запуск и остановка',
        weight: 15
    },
    {
        path: 'possession',
        label: 'Владение мячом',
        weight: 15
    },
    {
        path: 'accurate',
        label: 'Точные передачи',
        weight: 20
    },
    // {
    //     path: 'passes',
    //     label: 'Направление передач',
    //     weight: 15
    // },
    {
        path: 'events',
        label: 'Фиксация событий',
        weight: 15
    },
    {
        path: 'edit',
        label: 'Правка событий',
        weight: 10
    },
    {
        path: 'exam',
        label: 'Тестовый матч',
        weight: 15
    }
]

export const studyItems = items

export const studyProgress = skills => {
    return items.reduce((acc, i) => {
        if(skills.includes(i.path)) {
            acc += i.weight
        }
        return acc
    }, 0)
}
