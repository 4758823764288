import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import App from './App'
import Public from '@components/Public'
import { Callout, Button } from '@blueprintjs/core'
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import '../node_modules/@blueprintjs/icons/lib/css/blueprint-icons.css'
import '../node_modules/@blueprintjs/core/lib/css/blueprint.css'

Sentry.init({
      dsn: "https://c4fa853b31a24b3c9d02d43c1fbea03a@o1181451.ingest.sentry.io/6574539",
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: process.env.NODE_ENV !== 'production' ? 0.1 : 1.0,
      environment: process.env.NODE_ENV !== 'production' ? 'development' : 'production'
});

if(window.screen && window.screen.orientation) {
    window.screen.orientation.lock('landscape')
        .then(
            success => console.log('Orientation locked'),
            failure => console.log('Unable to lock orientation')
        )
}

let errInfo
const errHandler = (error: Error, info: {componentStack: string}) => {
    errInfo = info
}

const ErrFallback = ({ error, resetErrorBoundary }) => {
    const [info, setInfo] = useState(null)

    return  <div style={{padding: 20}}>
                <Callout
                    title='Ошибка приложения'
                    intent='danger'
                    icon='error'
                >
                    <p>{error.message}</p>
                    {info ? <pre>{info.componentStack}</pre> : null}
                </Callout>
                {!info ? <Button onClick={() => setInfo(errInfo)} text='Подробности' fill={true} large={true} style={{marginTop: 14}} /> : null}
                <Button onClick={() => resetErrorBoundary() } fill={true} large={true} text='Закрыть' intent='default' style={{marginTop: 14}} />
            </div>
}

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <ErrorBoundary
                FallbackComponent={ErrFallback}
                onReset={() => window.location.replace('/')}
                onError={errHandler}
            >
                <Switch>
                    <Route path='/public/:matchId'>
                        <Public />
                    </Route>
                    <Route path='/public'>
                        <Public />
                    </Route>
                    <Route path='/'>
                        <App />
                    </Route>
                </Switch>
            </ErrorBoundary>
        </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
