import React, { useState, useContext, useRef } from 'react'
import { Button, Popover, Menu, MenuItem, Alert } from '@blueprintjs/core'
import AppContext from '@ctx'
import RosterAlert from './RosterAlert'
import SelectableAlertBody from './SelectableAlertBody'
import { convertTime } from './utils'
import { storeEvent, updateEvent } from './store'
import displayTypes from './displayTypes'
import types from './types'
import axios from 'axios'

const Recent = ({ data, throwStats, matchData, rosters, mirror, ignoreApi }) => {
    const [alert, setAlert] = useState(null)
    const ctx = useContext(AppContext)

    const rosterValueRef = useRef(null)
    const subtypeRef = useRef(null)

    const showDeleteAlert = async e => {
        setAlert({
            confirmButtonText: 'Да, удалить',
            onConfirm: async () => {
                const stats = await storeEvent({...e, value: -1})
                throwStats(stats)

                if(e.markerId) {
                    await axios.delete(`${ctx.rest}markers/${e.markerId}`, {
                        headers: {
                            Authorization: localStorage.getItem('amstat_tkn')
                        }
                    })
                }
                setAlert(null)
            },
            cancelButtonText: 'Отмена',
            onCancel: async () => {
                setAlert(null)
            },
            icon: 'trash',
            intent: 'danger',
            body: 'Вы действительно хотите удалить событие?'
        })
    }

    const showTransferAlert = async (e, personalized) => {
        setAlert({
            confirmButtonText: 'Да, перенести',
            onConfirm: async () => {
                await storeEvent({...e, value: -1})
                const actualSide = e.side === 'home' ? 'away' : 'home'
                const stats = await storeEvent({...e, side: actualSide, person: null, transferTargetId: matchData[actualSide]._id})
                //ctx.logWorker.postMessage({type: 'transferedEvent', stamp: e.stamp, matchId: matchData.entry})
                throwStats(stats)
                setAlert(null)
                if(personalized && !ignoreApi) {
                    showRosterAlert({...e, side: actualSide, person: null})
                }
            },
            cancelButtonText: 'Отмена',
            onCancel: async () => {
                setAlert(null)
            },
            icon: 'trash',
            intent: 'warning',
            body: 'Вы действительно хотите перенести событие сопернику?'
        })
    }

    const showSubtypeAlert = async e => {
        const root = types.find(t => t.subtypes && t.subtypes.find(st => st.key === e.type))
        if(root) {
            setAlert({
                confirmButtonText: 'Подтвердить выбор',
                cancelButtonText: 'Отмена',
                subtypes: root.subtypes,
                initial: e.type,
                onCancel: () => {
                    setAlert(null)
                },
                onConfirm: async () => {
                    if(subtypeRef.current) {
                        setAlert(null)
                        //TODO apply logworker
                        await storeEvent({...e, value: -1})
                        await storeEvent({...e, type: subtypeRef.current.key})
                        const stats = await updateEvent({...e, type: subtypeRef.current.key})
                        throwStats(stats)
                        subtypeRef.current = null
                    }
                }
            })
        }
    }

    const showRosterAlert = async e => {
        setAlert({
            confirmButtonText: 'Подтвердить выбор',
            cancelButtonText: 'Закрыть',
            roster: rosters && rosters.rosters ? rosters.rosters[e.side] || {home: [], subs: []} : {home: [], subs: []},
            schema: rosters[e.side+'Schema'] ? '1-'+rosters[e.side+'Schema'] : '1-4-4-2',
            side: e.side,
            initial: e.person,
            onCancel: async () => {
                setAlert(null)
            },
            onConfirm: async () => {
                if(rosterValueRef && rosterValueRef.current && rosterValueRef.current.player) {
                    setAlert(null)
                    //TODO apply logworker
                    const stats = await updateEvent({...e, person: {...rosterValueRef.current}})
                    throwStats(stats)
                    rosterValueRef.current = null
                }
            }
        })
    }

    return  <div className='panel-item'>
                <div id='recentList'>
                {data && matchData && matchData.entity ? data.map((e, idx) => {
                    const matchedType = displayTypes.find(t => t.key === e.type)
                    return matchedType && e.side ? (
                        <div className={'recent-item'+(matchedType.personalized ? (!e.person || !e.person.player) ? ' danger' : e.person.needRefinement ? ' warning' : '' : '')} key={e.stamp}>
                            <div className='recent-item_time'>{e.overPlayed || convertTime(e.matchTime)}</div>
                            <div className='recent-item_emblem'>
                                <img src={matchData ? matchData[e.side].emblem || `${matchData.entity.externalBase}img/${matchData[e.side].logoId}.png` : null} />
                            </div>
                            <div
                                className='recent-item_label'
                                onClick={() => matchedType.fromSubtypes ? showSubtypeAlert(e) : null}
                            >{matchedType.label}</div>
                            <div
                                className='recent-item_person'
                                onClick={() => matchedType.personalized ? showRosterAlert(e, idx) : null}
                            >{matchedType.personalized && !ignoreApi ? [
                                <div key={`shit_${idx}`} className='shirt'>{e.person && e.person.player ? (<div>{e.person.player.num}</div>) : null}</div>,
                                <div className='name' key={`name_${idx}`}>{e.person && e.person.player ? e.person.player.surname : 'выбери игрока'}</div>
                            ] : null}</div>
                            <div className='recent-item_actions'>
                                <Popover
                                    content={(
                                        <Menu>
                                            <MenuItem
                                                icon='swap-horizontal'
                                                text='Перенести сопернику'
                                                onClick={() => showTransferAlert(e, matchedType.personalized)}
                                            />
                                            <MenuItem
                                                icon='delete'
                                                text='Удалить событие'
                                                onClick={() => showDeleteAlert(e)}
                                            />
                                        </Menu>
                                    )}
                                    usePortal={false}
                                >
                                    <Button icon='menu' text='' minimal={true} />
                                </Popover>
                            </div>
                        </div>
                    ) : null
                }) : null}
                {alert ? alert.roster ? (
                    <Alert className='roster-alert' {...alert} isOpen={true} intent='primary'>
                        <RosterAlert
                            onSelect={obj => rosterValueRef.current = {...obj}}
                            side={mirror ? alert.side === 'home' ? 'away' : 'home' : alert.side}
                            initial={alert.initial}
                            roster={alert.roster}
                            schema={alert.schema}
                        />
                    </Alert>
                ) : alert.subtypes ? (
                    <Alert className='custom' {...alert} isOpen={true} intent='primary'>
                        <SelectableAlertBody
                            title='Изменить тип события'
                            subtypes={alert.subtypes}
                            initial={alert.initial}
                            onSelect={st => subtypeRef.current = ({...st})}
                        />
                    </Alert>
                ) : (
                    <Alert {...alert} isOpen={true}>{alert.body}</Alert>
                ) : null}
                </div>
            </div>
}

export default Recent
