module.exports = [
    {key: 'score', label: 'Взятие ворот', hasBar: true, filter: true, personalized: true},
    {key: 'possession', label: 'Владение', perc: true},
    {
        key: 'shot',
        label: 'Удар',
        hasBar: true,
        personalized: true,
        subtypes: [
            {
                key: 'shot',
                label: 'Неточный',
                icon: 'missed'
            },
            {
                key: 'onTarget',
                label: 'В створ',
                icon: 'save'
            },
            {
                key: 'post',
                label: 'В каркас',
                icon: 'goal-post'
            }
        ]
    },
    //{key: 'onTarget', label: 'Удар в створ', hasBar: true},
    //{key: 'shot', label: 'Неточный удар', hasBar: true},
    // {key: 'blocked', label: 'Заблокированный удар', hasBar: true},
    //{key: 'post', label: 'Удар в каркас', hasBar: true},
    // {key: 'out', label: 'Аут', hasBar: true},
    {key: 'corner', label: 'Уголовой', hasBar: true, personalized: true},
    {key: 'foul', label: 'Нарушение правил', hasBar: true, personalized: true},
    // {key: 'transition', label: 'Подборы/Отборы/Перехваты'},
    {key: 'offside', label: 'Оффсайд', hasBar: true, personalized: true},
    {key: 'yellow', label: 'Предупреждение', hasBar: true, personalized: true},
    {key: 'red', label: 'Удаление', hasBar: true, personalized: true},
    {key: 'pass_short', label: 'Короткий пас', filter: true},
    {key: 'pass_medium', label: 'Средний пас', filter: true},
    {key: 'pass_far', label: 'Длинный пас', filter: true},
    {key: 'accurate_short', label: 'Точные короткие передачи', filter: true},
    {key: 'accurate_medium', label: 'Точные средние передачи', filter: true},
    {key: 'accurate_far', label: 'Точные длинные передачи', filter: true},
    {key: 'accurate_total', label: 'Точные передачи', filter: true},
    {key: 'directions', label: null, filter: true},
    {key: 'substitution', label: 'Замена', hasBar: true, personalized: true, filter: true},
    {key: 'assist', label: 'Ассист', personalized: true, filter: true}
]
