import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { Toaster } from '@blueprintjs/core'
import Item from './Item'

import './style.css'

const Public = () => {
    const { matchId } = useParams()
    const toaster = useRef(null)

    return  <div id='public'>
                <div className='container'>
                    {matchId ? (
                        <Item matchId={matchId} toaster={toaster} />
                    ) : (
                        <div>No item specified
                        </div>
                    )}
                </div>
                <Toaster ref={toaster} />
            </div>
}

export default Public
