import React, { useState, useEffect, useContext, useRef } from 'react'
import { Spinner, NonIdealState, Icon, Tag, Button } from '@blueprintjs/core'
import { useHistory } from 'react-router-dom'
import AppContext from '@ctx'
import CustomScrollbars from 'react-custom-scrollbars-2'
import axios from 'axios'
import './style.css'
import moment from 'moment'
moment.locale('ru')

const List = props => {
    const [list, setList] = useState(null)
    const [embRefs, setEmbRefs] = useState(null)
    const ctx = useContext(AppContext)
    const history = useHistory()

    useEffect(() => {
        navigator.serviceWorker.getRegistrations().then(function(registrations) {
            registrations.forEach(function(v) { console.log('service worker: ' + v) });
        })

        history.listen((loc, act) => {
            if(act === 'POP') {
                window.location.reload()
            }
        })

        if(!ctx.auth) {
            history.push('/')
        } else {
            loadList()
        }
    }, [])

    const loadList = (forced=null) => {
        setList(null)
        axios.get(`${forced || 'https://stats.amateum.com/'}client/init`, {
                headers: {
                    'Authorization': localStorage.getItem('amstat_tkn')
                }
            }).then(async list => {
                if(list.data.isStaging) {
                    localStorage.setItem('amstat_endpoint', 'https://staging.amateum.com/')
                } else {
                    localStorage.setItem('amstat_endpoint', 'https://stats.amateum.com/')
                }

                if(!forced) {
                    if(list.data.isStaging) {
                        setTimeout(() => {
                            loadList('https://staging.amateum.com/')
                        }, 1000)
                    } else {
                        setTimeout(() => {
                            setList(list.data.feeds)
                        }, 1000)
                    }
                } else {
                    setTimeout(() => {
                        setList(list.data.feeds)
                    }, 1000)
                }
            })
    }

    const setMatch = mid => {
        ctx.setCtx('matchData', list.find(m => m._id === mid))
        history.push('/matches/'+mid)
    }

    const ep = localStorage.getItem('amstat_endpoint')

    return  <div className='screen scroll-screen'>
                {!list ? (
                    <NonIdealState
                        style={{height: '60vh'}}
                        children={(<Spinner size={80} />)}
                        description='загрузка...'
                    />
                ) : list.length === 0 ? (
                    <NonIdealState
                        style={{height: '60vh'}}
                        icon='refresh'
                        description='нет доступных матчей'
                        action={<Button text='Обновить' intent='none' onClick={() => loadList()} />}
                    />
                ) : (
                    <div className='screen-list'>
                        <CustomScrollbars autoHeight autoHeightMin='calc(100vh - 50px)'>
                        {list.map(m => (
                            <div key={m._id} className='screen-list_item match-item' key={m._id} onClick={() => setMatch(m._id)}>
                                <div className='match-item_labels'>
                                    {ep.includes('staging') ? <Tag intent='warning'>TEST</Tag> : null}
                                    {m.postProduction ? <Tag intent='danger'>LIVE</Tag> : null}
                                </div>
                                <div className='match-item_datetime'><Tag minimal={true}>{moment(m.match.date, 'YY-MM-DD').format('DD.MM')} {m.match.time}</Tag></div>
                                <div className='match-item_teams'>
                                    <div>
                                        <span>{m.match.home.fullName}</span>
                                        <div className='match-item_teams_emb'>
                                            <img src={m.match.home.emblem || `${m.externalBase}img/${m.match.home.logoId}.png`} />
                                        </div>
                                    </div>
                                    <div style={{padding: '0 6px'}}>
                                        <Icon icon='cross' />
                                    </div>
                                    <div>
                                        <div className='match-item_teams_emb'>
                                            <img src={m.match.away.emblem || `${m.externalBase}img/${m.match.away.logoId}.png`} />
                                        </div>
                                        <span>{m.match.away.fullName}</span>
                                    </div>
                                </div>
                                <div className='match-item_tags' style={{height: 12}}>
                                    {/*<Tag>{m.entity.periods} x {m.entity.duration} мин</Tag>
                                    <Tag>точные передачи</Tag>
                                    <Tag>с оффсайдами</Tag>*/}
                                </div>
                            </div>
                        ))}
                        </CustomScrollbars>
                    </div>
                )}
            </div>
}

export default List
