import React, { useState, useEffect } from 'react'

const SelectableAlertBody = ({ subtypes, title, onSelect, initial }) => {
    const [selected, setSelected] = useState(initial ? subtypes.find(st => st.key === initial) : null)

    useEffect(() => {
        if(selected) {
            onSelect({...selected})
        }
    }, [selected])

    return  <div>
                <b>{title}:</b>
                <div className='subtypes-grid'>
                    {subtypes.map((st, idx) => (
                        <div key={`selectable_${idx}`} className={'subtypes-item'+(selected && selected.key === st.key ? ' selected' : '')} onClick={() => setSelected({...st})}>
                            <div>
                                <img src={require(`@icons/${st.icon}.png`).default} />
                            </div>
                            <span>{st.label}</span>
                        </div>
                    ))}
                </div>
            </div>
}

export default SelectableAlertBody
