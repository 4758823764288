import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import AppContext from '@ctx'
import { ControlGroup, InputGroup, Button } from '@blueprintjs/core'
import axios from 'axios'

const Authorization = () => {
    const [login, setLogin] = useState(null)
    const [pass, setPass] = useState(null)
    const [progress, setProgress] = useState(false)

    const ctx = useContext(AppContext)
    const history = useHistory()

    const doAuth = () => {
        if(!pass || pass.length < 4) {
            ctx.toaster.current.show({intent: 'warning', message: 'Проверьте пароль'})
        } else if(!login || login.length < 4) {
            ctx.toaster.current.show({intent: 'warning', message: 'Проверьте имя пользователя'})
        } else {
            setProgress(true)
            axios.post(`https://stats.amateum.com/auth/staffs`, {login: login, pass: pass})
                .then(resp => {
                    setProgress(false)
                    if(resp.data.error) {
                        ctx.toaster.current.show({intent: 'danger', message: resp.data.message})
                    } else {
                        const { token, isStaging, ...authData } = resp.data
                        localStorage.setItem('amstat_tkn', token)
                        localStorage.setItem('amstat_auth', JSON.stringify(authData))
                        localStorage.setItem('amstat_endpoint', `https://${isStaging ? 'staging' : 'stats'}.amateum.com/`)
                        ctx.setCtx('auth', authData)
                        history.push('/matches')
                    }
                })
        }
    }

    return  <div className='body-form'>
                <div className='form-brand'>
                    <img src={require('@icons/brand.png').default} />
                </div>
                <ControlGroup>
                    <label>Имя пользователя:</label>
                    <InputGroup fill={true} large={true} value={login} onChange={e => setLogin(e.target.value)}/>
                </ControlGroup>
                <ControlGroup style={{marginTop: 24, marginBottom: 24}}>
                    <label>Пароль:</label>
                    <InputGroup fill={true} type='password' large={true} value={pass} onChange={e => setPass(e.target.value)} />
                </ControlGroup>
                <Button disabled={progress} onClick={() => doAuth()} loading={progress} intent='primary' large={true} fill={true} text='Авторизоваться' icon='confirm' />
            </div>
}

export default Authorization
