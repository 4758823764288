import React, { useState, useEffect, useRef } from 'react'
import { Spinner, NonIdealState, Tag, ProgressBar } from '@blueprintjs/core'
import Stats from '../Match/Stats'
import axios from 'axios'

const base = process.env.NODE_ENV === 'production' ? 'https://rest.amateum.com/' : 'http://localhost:3003/'
const INTERVAL = 5000

const Item = ({ matchId, toaster }) => {
    const [stats, setStats] = useState(null)
    const [loading, setLoading] = useState(true)


    const loadData = () => {
        setLoading(true)

        axios.get(`${base}statentry/${matchId}`)
            .then(resp => {
                if(!resp.data) {
                    toaster.current.show({intent: 'danger', message: 'Ошибка загрузки данных'})
                } else {
                    setTimeout(() => {
                        setStats(resp.data)
                    }, 1000)
                }

                setTimeout(() => {
                    if(toaster.current) {
                        toaster.current.clear()
                    }
                }, 1000)
                setLoading(false)
            })
    }

    useEffect(() => {
        loadData()
    }, [])

    useEffect(() => {
        if(!loading) {
            const interval = setInterval(() => {
                loadData()
            }, INTERVAL)

            return () => {
                clearInterval(interval)
            }
        }
    }, [loading])

    return  !stats ? (
                <div>
                    <NonIdealState
                        style={{height: 200}}
                        children={(<Spinner size={80} />)}
                        description='загрузка...'
                    />
                </div>
            ) : [
                <div className='stats-top'>
                    {stats.homeClub ? <div className='emb' style={{backgroundImage: `url(${stats.homeClub.emblem})`}}></div> : null}
                    <div className='score'>
                        {stats.data ? <Tag intent='primary'>{Math.ceil(stats.data.time/60)} минута</Tag> : null}
                        {stats.data ? stats.data.home.score+':'+stats.data.away.score : '- : -'}
                    </div>
                    {stats.awayClub ? <div className='emb' style={{backgroundImage: `url(${stats.awayClub.emblem})`}}></div> : null}
                </div>,
                <div className='progress'></div>,
                <div style={{textAlign: 'center'}}>
                    {stats.data ? <Stats
                        data={stats.data}
                        hasOffsides={stats.entity && stats.entity.offsides}
                        passesMode={stats.entity ? stats.entity.passesMode : null}
                    /> : <span>нет данных...</span>}
                </div>
            ]
}

export default Item
