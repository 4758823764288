const BALL_SIZE = 80
const BALL_XS = BALL_SIZE*1.2
const BALL_YS = BALL_SIZE*0.6

export const normalizeStatsKeys = body => {
    const keys = ['score', 'possession', 'accurate_total', 'shot', 'onTarget', 'frame', 'corner', 'foul', 'offside', 'yellow', 'red']
    return ['home', 'away'].reduce((acc, side) => {
        acc[side] = keys.reduce((acc, k) => {
            acc[k] = body[side][k]
            return acc
        }, {})
        return acc
    }, {})
 }

export const convertTime = input => {
    if(!isNaN(parseInt(input))) {
        const mins = Math.floor(parseInt(input)/60)
        const sec = (input+60)%60
        return `${mins < 10 ? '0' : ''}${mins}:${sec < 10 ? '0' : ''}${sec}`
    } else {
        return '00:00'
    }
}

export const prepareRosters = obj => {
    return Object.keys(obj).reduce((acc, side) => {
        acc[side] = Object.keys(obj[side]).reduce((acc, section) => {
            acc[section] = obj[side][section].map(p => p._id)
            return acc
        }, {})
        return acc
    }, {})
}

export const createSnapshot = (stats, time) => {
    console.log(stats)
    const keys = {
        score: {key: 'score'},
        possession: {key: 'possession', perc: true},
        passesCompleted: {key: 'accurate_total'},
        shotsTotal: {aggregate: ['shot', 'onTarget', 'post']},
        shotsOnTarget: {key: 'onTarget'},
        frameShots: {key: 'post'},
        corners: {key: 'corner'},
        offsides: {key: 'offside'},
        fouls: {key: 'foul'},
        yellowCards: {key: 'yellow'},
        redCards: {key: 'red'}
    }

    const _stats = Object.keys(keys).reduce((acc, k) => {
        const item = keys[k]
        const obj = {key: k}
        for(let side of ['home', 'away']) {
            if(item.perc) {
                const total = stats.home[item.key]+stats.away[item.key]
                obj[side] = total === 0 ? '50%' : (side === 'home' ? Math.ceil((stats.home[item.key]/total)*100) : Math.floor((stats.away[item.key]/total)*100))+'%'
            } else if (item.aggregate) {
                obj[side] = item.aggregate.reduce((acc, a) => {
                    acc += stats[side][a]
                    return acc
                }, 0)
            } else {
                obj[side] = stats[side][item.key]
            }
        }

        acc.push(obj)
        return acc
    }, [])

    const splitted = splitTime(time)

    return {
        'snapshot.stats': _stats,
        'snapshot.matchMinutes': splitted.mins,
        'snapshot.matchSeconds': splitted.secs
    }
}

export const splitTime = input => {
    if(!isNaN(parseInt(input))) {
        const mins = Math.floor(parseInt(input)/60)
        const sec = (input+60)%60
        return {mins: mins, secs: sec}
    } else {
        return {mins: 0, secs: 0}
    }
}

export const periodPromts = {
    'null': 'первый тайм',
    '1T': 'первый тайм',
    '1Tf': 'второй тайм',
    '2T': 'второй тайм',
    '2Tf': 'третий период',
    '3T': 'третий период',
    '3Tf': 'четвёртый период',
    '4T': 'четвёртый период',
    '4Tf': 'первый экстра-тайм',
    '1ET': 'первый экстра-тайм',
    '1ETf': 'второй экстра-тайм',
    '2ET': 'второй эктра-тайм'
}

export const handlePassDirection = (p, pos) => {
    const reverse = pos === 'away'
    switch (true) {
        case p.x > BALL_XS && Math.abs(p.y) < BALL_YS:
            return reverse ? 'bck' : 'fw'
            break
        case p.x > BALL_XS && p.y > BALL_YS:
            return reverse ? 'bckleft' : 'fwright'
            break
        case p.x > BALL_XS && p.y < (0 - BALL_YS):
            return reverse ? 'bckright' : 'fwleft'
            break
        case Math.abs(p.x) < BALL_XS && p.y < (0 - BALL_YS):
            return reverse ? 'right' : 'left'
            break
        case Math.abs(p.x) < BALL_XS && p.y > BALL_YS:
            return reverse ? 'left' : 'right'
            break
        case p.x < BALL_XS && Math.abs(p.y) < BALL_YS:
            return reverse ? 'fw' : 'bck'
            break
        case p.x < BALL_XS && p.y > BALL_YS:
            return reverse ? 'fwleft' : 'bckright'
            break
        case p.x < BALL_XS && p.y < (0 - BALL_YS):
            return reverse ? 'fwright' : 'bckleft'
            break
        default:
            return null
    }
}

export const wrongPassMessage = (_exp, _fact) =>  {
    let msg = 'Всё не так!'
    const exp = _exp.split('_')
    const fact = _fact.split('_')

    switch (true) {
        case exp[0] !== fact[0]:
            msg = 'Неверное направление'
            break
        case exp[1] === 'short' && fact[1] !== 'short':
        case exp[1] === 'medium' && fact[1] === 'far':
            msg = 'Слишком долго!'
            break
        case exp[1] !== 'short' && fact[1] === 'short':
        case exp[1] === 'far' && fact[1] === 'medium':
            msg = 'Слишком быстро!'
            break
    }

    return msg
}
