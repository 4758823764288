import React, { useState, useRef, useEffect } from 'react'
import { motion, useMotionValue } from 'framer-motion'
import { Alert } from '@blueprintjs/core'
import RosterAlert from './RosterAlert'
import SelectableAlertBody from './SelectableAlertBody'
const types = require('./types')

let bars = types.filter(t => t.hasBar)

const Item = ({ data, onEvent, ticker, mirror, rosters, hasOffsides, onUpdatedRosters, time, ignoreApi }) => {
    const [dropped, setDropped] = useState(false)
    const [locked, setLocked] = useState(false)
    const [alert, setAlert] = useState(null)

    const subtypeRef = useRef(null)
    const rosterValueRef = useRef(null)
    const substituteRef = useRef(null)

    const x = useMotionValue(0)
    const y = useMotionValue(0)

    const resetDropped = () => {
        setLocked(false)
        setDropped(false)
    }

    const onDragEnd = () => {
        setDropped(true)
        setTimeout(resetDropped, 100)
    }

    const hiddenClass = data.key === 'offside' && !hasOffsides ? ' _hidden' : ''

    const handleDrag = (evt, info) => {
        const { x } = info.offset
        const side = x < 0 ? mirror ? 'away' : 'home' : mirror ? 'home' : 'away'

        if(Math.abs(x) > 190 && !locked) {
            setLocked(true)
            navigator.vibrate([200])
            if(ticker) {
                if(data.key === 'substitution') {
                    setAlert({
                        confirmButtonText: 'Подтвердить выбор',
                        cancelButtonText: 'Отмена',
                        roster: rosters.rosters[side],
                        schema: rosters[side+'Schema'] ? '1-'+rosters[side+'Schema'] : '1-4-4-2',
                        substitution: true,
                        side: side,
                        onCancel: () => {
                            setAlert(null)
                            rosterValueRef.current = null
                            substituteRef.current = null
                        },
                        onConfirm: () => {
                            if(rosterValueRef.current && rosterValueRef.current.player && substituteRef.current) {
                                const updRosters = {
                                    ...rosters.rosters,
                                    [side]: {
                                        main: rosters && rosters.rosters && rosters.rosters[side] && rosters.rosters[side].main ? rosters.rosters[side].main.map(p => p._id === rosterValueRef.current.player._id ? {...substituteRef.current} : p) : [],
                                        subs: rosters.rosters[side].subs.map(p => p._id === substituteRef.current._id ? {...rosterValueRef.current.player} : p)
                                    }
                                }

                                onUpdatedRosters(updRosters)
                                setAlert(null)
                                rosterValueRef.current = null
                                substituteRef.current = null
                            }
                        }
                    })
                } else if(data.subtypes) {
                    setAlert({
                        confirmButtonText: 'Подтвердить выбор',
                        cancelButtonText: 'Отмена',
                        subtypes: data.subtypes,
                        onCancel: () => {
                            setAlert(null)
                        },
                        onConfirm: () => {
                            if(subtypeRef.current) {
                                if(!data.personalized || ignoreApi) {
                                    setAlert(null)
                                    onEvent({
                                        type: subtypeRef.current.key,
                                        stamp: new Date().getTime(),
                                        side: x < 0 ? mirror ? 'away' : 'home' : mirror ? 'home' : 'away',
                                        label: data.label,
                                        value: 1,
                                        fixedTime: time
                                    })
                                    subtypeRef.current = null
                                } else {
                                    setAlert({
                                        confirmButtonText: 'Подтвердить выбор',
                                        cancelButtonText: 'Отметить позднее',
                                        roster: rosters && rosters.rosters ? rosters.rosters[side] || {home: [], subs: []} : {home: [], subs: []},
                                        schema: rosters[side+'Schema'] ? '1-'+rosters[side+'Schema'] : '1-4-4-2',
                                        side: side,
                                        onCancel: () => {
                                            setAlert(null)
                                            onEvent({
                                                type: subtypeRef.current.key,
                                                person: null,
                                                stamp: new Date().getTime(),
                                                side: x < 0 ? mirror ? 'away' : 'home' : mirror ? 'home' : 'away',
                                                label: data.label,
                                                value: 1,
                                                fixedTime: time
                                            })
                                            subtypeRef.current = null
                                            rosterValueRef.current = null
                                        },
                                        onConfirm: () => {
                                            if(rosterValueRef && rosterValueRef.current && rosterValueRef.current.player) {
                                                onEvent({
                                                    type: subtypeRef.current.key,
                                                    person: rosterValueRef.current,
                                                    stamp: new Date().getTime(),
                                                    side: x < 0 ? mirror ? 'away' : 'home' : mirror ? 'home' : 'away',
                                                    label: data.label,
                                                    value: 1,
                                                    fixedTime: time
                                                })
                                                setAlert(null)
                                                subtypeRef.current = null
                                                rosterValueRef.current = null
                                            }
                                        }
                                    })
                                }
                            }
                        }
                    })
                } else {
                    if(!data.personalized || ignoreApi) {
                        onEvent({
                            type: data.key,
                            stamp: new Date().getTime(),
                            side: x < 0 ? mirror ? 'away' : 'home' : mirror ? 'home' : 'away',
                            label: data.label,
                            value: 1,
                            fixedTime: time
                        })
                    } else {
                        setAlert({
                            confirmButtonText: 'Подтвердить выбор',
                            cancelButtonText: 'Отметить позднее',
                            roster: rosters && rosters.rosters ? rosters.rosters[side] || {home: [], subs: []} : {home: [], subs: []},
                            schema: rosters[side+'Schema'] ? '1-'+rosters[side+'Schema'] : '1-4-4-2',
                            side: side,
                            onCancel: () => {
                                setAlert(null)
                                onEvent({
                                    type: data.key,
                                    person: null,
                                    stamp: new Date().getTime(),
                                    side: x < 0 ? mirror ? 'away' : 'home' : mirror ? 'home' : 'away',
                                    label: data.label,
                                    value: 1,
                                    fixedTime: time
                                })
                                rosterValueRef.current = null
                            },
                            onConfirm: () => {
                                if(rosterValueRef && rosterValueRef.current && rosterValueRef.current.player) {
                                    onEvent({
                                        type: data.key,
                                        person: rosterValueRef.current,
                                        stamp: new Date().getTime(),
                                        side: x < 0 ? mirror ? 'away' : 'home' : mirror ? 'home' : 'away',
                                        label: data.label,
                                        value: 1,
                                        fixedTime: time
                                    })
                                    setAlert(null)
                                    rosterValueRef.current = null
                                    if(data.key === 'score') {
                                        setTimeout(() => {
                                            setAlert({
                                                confirmButtonText: 'Подтвердить ассистента',
                                                cancelButtonText: 'Без ассиста',
                                                roster: rosters.rosters[side],
                                                schema: rosters[side+'Schema'] ? '1-'+rosters[side+'Schema'] : '1-4-4-2',
                                                side: side,
                                                onCancel: () => {
                                                    setAlert(null)
                                                    rosterValueRef.current = null
                                                },
                                                onConfirm: () => {
                                                    if(rosterValueRef && rosterValueRef.current && rosterValueRef.current.player) {
                                                        onEvent({
                                                            type: 'assist',
                                                            person: rosterValueRef.current,
                                                            stamp: new Date().getTime(),
                                                            side: x < 0 ? mirror ? 'away' : 'home' : mirror ? 'home' : 'away',
                                                            label: 'Ассист',
                                                            value: 1,
                                                            fixedTime: time
                                                        })
                                                        rosterValueRef.current = null
                                                        setAlert(null)
                                                    }
                                                }
                                            })
                                        }, 1000)
                                    } else {
                                        setAlert(null)
                                    }
                                }
                            }
                        })
                    }
                }
            } else {
                setAlert({
                    confirmButtonText: 'Да, добавить',
                    cancelButtonText: 'Отменить',
                    onConfirm: () => {
                        setAlert(null)
                        onEvent({
                            type: data.key,
                            stamp: new Date().getTime(),
                            side: x < 0 ? mirror ? 'away' : 'home' : mirror ? 'home' : 'away',
                            label: data.label,
                            value: 1,
                            fixedTime: time
                        })
                    },
                    onCancel: () => {
                        setAlert(null)
                    }
                })
            }
        }
    }

    return  [<motion.div
                drag='x'
                id={data.key+'ControlBar'}
                className={'bar-item'+hiddenClass}
                onDragEnd={onDragEnd}
                dragConstraints={{left: '50vw', right: '50vw'}}
                dragElastic={0}
                animate={dropped ? {x, y} : {}}
                style={{x, y}}
                onDrag={handleDrag}
                key='motion_node'
            >
                {data.label}
            </motion.div>,
            alert ? alert.subtypes ? (
                <Alert key='alert_node' className='custom' {...alert} isOpen={true} intent='primary'>
                    <SelectableAlertBody
                        title='Выберите тип события'
                        subtypes={alert.subtypes}
                        onSelect={st => subtypeRef.current = ({...st})}
                    />
                </Alert>
            ) : alert.roster ? (
                <Alert key='alert_node' className='roster-alert' {...alert} isOpen={true} intent='primary'>
                    <RosterAlert
                        onSelect={obj => rosterValueRef.current = {...obj}}
                        onSubSelect={alert.substitution ? (obj) => substituteRef.current = ({...obj}) : null}
                        side={mirror ? alert.side === 'home' ? 'away' : 'home' : alert.side}
                        roster={alert.roster}
                        schema={alert.schema}
                    />
                </Alert>
            ) : (
                <Alert key='alert_node' {...alert} isOpen={true} intent='primary' icon='stopwatch'>Время матча не запущено. Вы действительно хотите добавить событие?</Alert>
            ) : null
        ]
}

const Bars = ({ onEvent, ticker, mirror, hasOffsides, rosters, onUpdatedRosters, time, ignoreApi }) => {
    const transferEvt = e => {
        onEvent(e)
    }

    const _bars = ignoreApi ? bars.filter(b => b.key !== 'substitution') : [...bars]

    return  <div className='panel-item'>
                {_bars.map(bar => (
                    <Item
                        onEvent={transferEvt}
                        ticker={ticker}
                        key={'bar_'+bar.key}
                        data={bar}
                        mirror={mirror}
                        hasOffsides={hasOffsides}
                        rosters={rosters}
                        onUpdatedRosters={onUpdatedRosters}
                        time={time}
                        ignoreApi={ignoreApi}
                    />
                ))}
            </div>
}

export default Bars
