import React, { useState, useContext } from 'react'
import { Button } from '@blueprintjs/core'
import AppContext from '@ctx'
import { periodPromts } from '@components/Match/utils'

import './style.scss'

const MatchStageDialog = ({ period, next, fromStudy, onPeriodLaunched }) => {
    const [option, setOption] = useState(null)
    const ctx = useContext(AppContext)

    const confirm = () => {
        if(option) {
            ctx.setCtx('dialog', null)
            onPeriodLaunched(option)
        }
    }

    return  <div id='matchStateDialog'>
                <div className='options'>
                    <div className='options-row'>
                        <div className={'option'+(option === next ? ' selected' : '')} onClick={() => setOption(next)}>
                            <img src={require('@icons/whistle.png').default} />
                            <b>Перерыв, затем {periodPromts[next]}</b>
                        </div>

                        {!period.includes('E') ? (
                            <div className={'option'+(option === '4Tf' ? ' selected' : '')} onClick={() => setOption('4Tf')} >
                                <img src={require('@icons/trophy.png').default} />
                                <b>Перерыв, затем первый экстра-тайм</b>
                            </div>
                        ) : null}
                    </div>
                    <div className='options-row'>
                        <div className={'option'+(option === 'finished' ? ' selected' : '')} onClick={() => setOption('finished')}>
                            <img src={require('@icons/scoreboard.png').default} />
                            <b>Завершить матч</b>
                        </div>
                    </div>
                </div>

                <div className='actions'>
                    <Button fill={true} intent='primary' large={true} style={{marginBottom: 8}} text='Подтвердить выбор' onClick={() => {
                        confirm()
                    }} />

                    <Button fill={true} large={true} text='Отмена, вернуться' onClick={() => {
                        ctx.setCtx('dialog', null)
                    }} />
                </div>
            </div>
}

export default MatchStageDialog
