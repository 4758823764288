import React, { useRef, useState, useEffect, useContext } from 'react'
import AppContext from '@ctx'
import { motion, useMotionValue } from 'framer-motion'
import { storeEvent, initStore } from './store'
import { handlePassDirection, wrongPassMessage } from './utils'
import { Alert, Toaster, Position } from '@blueprintjs/core'

const ACCURATE_TIMEOUT = 700
const ACC_CHECK_INTERVAL = 250
const DISTANCE_STEPS = [1000, 800, 600]
const DISTANCES = [null, 'short', 'medium', 'far']
const UNACTIVE_EDGE = 25
const types = require('./types')

const Control = ({ ticker, storedMarkers, backupedStats, throwStats, onEvent, matchId, initStoredMatch, applyStoredState, matchData, mirror, study, resetPossessionCallback, stepsRef, accMode, lockedPasses, autoStopTrigger, resetAutoStopTrigger }) => {
    //possession side
    const [pos, setPos] = useState(null)
    const [dropped, setDropped] = useState(false)
    const [unactiveCnt, setUnactiveCnt] = useState(0)
    const [dragPoint, setDragPoint] = useState(null)
    const [passDistance, setPassDistance] = useState(null)
    const [alert, setAlert] = useState(null)
    const [ballPosition, setBallPosition] = useState(null)
    const [accStudy, setAccStudy] = useState({home: 0, away: 0})
    const ballBoundary = useRef(null)
    const controlToasterRef = useRef(null)
    const tapStarted = useRef(null)

    const ctx = useContext(AppContext)

    const x = useMotionValue(0)
    const y = useMotionValue(0)

    //INIT STORAGE
    useEffect(() => {
        if(storedMarkers) {
            initStore(matchId, initStoredMatch, applyStoredState, ctx.logWorker, storedMarkers, backupedStats)
                .then(init => {
                    console.log('Inited', init)
                    throwStats(init)
                })
        }
    }, [storedMarkers])

    //RESET POSSESSION AFTER TIMEOUT WITHOUT DRAG
    useEffect(() => {
        if(unactiveCnt < UNACTIVE_EDGE || study) {
            const interval = setInterval(() => {
                if(pos && ticker) {
                    setUnactiveCnt(unactiveCnt+1)
                    onEvent({type: 'possession', side: mirror ? pos === 'home' ? 'away' : 'home' : pos, stamp: new Date().getTime(), value: 1})
                }
            }, 1000)

            let accInterval
            if(accMode) {
                accInterval = setInterval(() => {
                    if(tapStarted.current && typeof(tapStarted.current) === 'number') {
                        if((new Date().getTime() - tapStarted.current) > ACCURATE_TIMEOUT) {
                            tapStarted.current = 'catch'
                            ctx.device.vibr(['400'])
                        }
                    }
                }, ACC_CHECK_INTERVAL)
            }

            return () => {
                try {
                    clearInterval(interval)
                    if(accMode) {
                        clearInterval(accInterval)
                    }
                } catch(e) {
                    console.log('no interval')
                }
            }
        } else {
            setPos(null)
            setUnactiveCnt(0)
        }
    }, [unactiveCnt, pos])

    useEffect(() => {
        if(!ticker && pos) {
            setPos(null)
            setUnactiveCnt(0)
        }
    }, [ticker])

    useEffect(() => {
        if(autoStopTrigger) {
            setPos(null)
            setUnactiveCnt(0)
            ctx.device.vibr([200])
            resetAutoStopTrigger()
        }
    }, [autoStopTrigger])

    //HANDLE PASS-DISTANCE TYPE
    useEffect(() => {
        if(dragPoint) {
            setPassDistance(DISTANCES[0])
            let idx = 0

            const curAvgDist = () => {
                const sum = DISTANCE_STEPS.reduce((acc, v, i) => {
                    if(i < (idx+1)) {
                        acc += v
                    }
                    return acc
                }, 0)

                return sum/(idx+1)
            }

            const AVG = curAvgDist()

            const interval = setInterval(() => {
                const DIST = DISTANCE_STEPS[idx]
                const AVG = curAvgDist()
                const offset = Math.floor((new Date().getTime() - dragPoint)/AVG)
                if(DISTANCES[offset]) {
                    setPassDistance(DISTANCES[offset])
                    const pattern = []
                    for(let _i=0; _i<offset; _i++) { pattern.push(150, 50) }
                    console.log('VIBR', pattern, ballPosition)
                    ctx.device.vibr(pattern)
                    idx++
                }
            }, AVG)

            return () => {
                try {
                    clearInterval(interval)
                } catch(e) {
                    console.log('no interval')
                }
            }
        }
    }, [dragPoint])

    //SWITCH POSSESSION TEAM
    const handlePossession = side => {
        if(side !== 'pos' && !dropped) {
            if(side !== pos) {
                if(ticker) {
                    if(study && study.id === 'passes') {
                        if(study.currentStep === 1) {
                            stepsRef.current.introJs.goToStep(3)
                        }
                    }

                    setPos(side)
                    ctx.device.vibr([200])
                    setUnactiveCnt(0)
                } else {
                    setAlert(true)
                }
            } else {
                setPos(null)
                ctx.device.vibr([100])
                setUnactiveCnt(0)
                if(resetPossessionCallback) {
                    resetPossessionCallback()
                }
            }
        }
    }

    const resetDropped = () => {
        setDropped(false)
    }

    const onDragStart = (evt, info) => {
        setDragPoint(new Date().getTime())
    }

    //HANDLE BALL DRAG END EVT
    const onDragEnd = (evt, info) => {
        const direction = handlePassDirection(info.offset, pos)
        const stamp = new Date().getTime()
        if(passDistance) {
            const passKey = passDistance !== 'takeout' ? 'pass_'+passDistance : 'takeout'
            const evtLabel = types.find(t => t.key === passKey).label
            const _evt = {type: passKey, side: mirror ? pos === 'home' ? 'away' : 'home' : pos, stamp: stamp, value: 1, label: evtLabel, direction: direction}
            if(study && study.expected && direction) {
                if(direction+'_'+passDistance === study.expected) {
                    console.log(study.expected, direction+'_'+passDistance)
                    if(study.evtTransmitter) {
                        study.evtTransmitter(_evt)
                    } else {
                        stepsRef.current.introJs.goToStep(study.currentStep+2)
                    }
                } else {
                    controlToasterRef.current.show({intent: 'warning', message: wrongPassMessage(study.expected, direction+'_'+passDistance), timeout: 800})
                }
            }
            onEvent(_evt)
        }
        setDragPoint(null)
        setPassDistance(null)
        setDropped(true)
        setUnactiveCnt(0)
        setTimeout(resetDropped, 100)
    }

    const trackBall = (e, i) => {
        setBallPosition(i.point)
    }

    const tapStarter = () => {
        if(accMode && !tapStarted.current && pos && pos !== 'pos') {
            tapStarted.current = new Date().getTime()
        }
    }

    const handleAccStudy = e => {
        switch(true) {
            case e.side === 'home' && accStudy.home === 0:
                setAccStudy({...accStudy, home: 1})
                stepsRef.current.introJs.goToStep(4)
                break
            case e.side === 'home' && accStudy.away < 3:
                controlToasterRef.current.show({intent: 'danger', message: 'Переключите владение на команду справа от вас', timeout: 1000})
                break
            case e.side === 'away' && accStudy.away < 2:
                controlToasterRef.current.show({intent: 'primary', message: accStudy.away === 0 ? 'Ещё две передачи' : 'Ещё одна передача', timeout: 1000})
                setAccStudy({...accStudy, away: accStudy.away+1})
                break
            case e.side === 'away' && accStudy.away === 2:
                stepsRef.current.introJs.goToStep(5)
                setAccStudy({...accStudy, away: 3})
                break
            case e.side === 'away' && accStudy.away === 3:
                controlToasterRef.current.show({intent: 'danger', message: 'Переключите владение на команду слева от вас', timeout: 1000})
                break
            case e.side === 'home' && accStudy.away === 3:
                if(accStudy.home === 1) {
                    controlToasterRef.current.show({intent: 'primary', message: 'Ещё одна передача', timeout: 1000})
                    setAccStudy({...accStudy, home: 2})
                } else {
                    stepsRef.current.introJs.goToStep(6)
                    setAccStudy({...accStudy, home: 3})
                }
                break
        }
    }

    const tapHandler = () => {
        if(accMode) {
            if(tapStarted.current && tapStarted.current === 'catch') {
                const evt = {type: 'accurate_total', side: mirror ? pos === 'home' ? 'away' : 'home' : pos, stamp: new Date().getTime(), value: 1, label: 'Точный пас'}
                setUnactiveCnt(0)
                onEvent(evt)
                if(study && accMode && stepsRef && stepsRef.current) {
                    handleAccStudy(evt)
                }
            }

            tapStarted.current = null
        }
    }

    const home = matchData ? mirror ? matchData.away : matchData.home : null
    const away = matchData ? mirror ? matchData.home : matchData.away : null

    return  <div className='control' id='controls'>
                <div className='control-inner'>
                    <motion.div
                        id='homePossessionControl'
                        className={'team-indicator'+(pos === 'home' ? ' _possession' : '')}
                        onTap={() => handlePossession('home')}
                    >
                        <img src={home ? home.emblem || `${matchData.entity.externalBase}img/${home.logoId}.png` : null} />
                    </motion.div>

                    {!(study && study.id === 'possession') && !lockedPasses ? <div className={'track-area'+(!pos ? ' _muted' : '')} id='trackArea' ref={ballBoundary}>
                        <motion.img
                            onContextMenu={_e => _e.preventDefault()}
                            onDragEnd={onDragEnd}
                            onDragStart={onDragStart}
                            animate={dropped ? {x, y} : {}}
                            transition={dropped ? {duration: 1, repeat: null} : {}}
                            drag={pos && !accMode ? true : false}
                            onTapStart={() => tapStarter()}
                            onTap={() => tapHandler()}
                            onDrag={trackBall}
                            dragConstraints={ballBoundary}
                            src={require('./ball.png').default}
                            style={{x, y}}
                        />
                    </div> : null}

                    <motion.div
                        id='awayPossessionControl'
                        className={'team-indicator'+(pos === 'away' ? ' _possession' : '')}
                        onTap={() => handlePossession('away')}
                    >
                        <img src={away ? away.emblem || `${matchData.entity.externalBase}img/${away.logoId}.png` : null} />
                    </motion.div>
                </div>

                {alert ? (
                    <Alert isOpen={true} intent='primary' onConfirm={() => setAlert(null)} icon='stopwatch'>Время матча не запущено. Откройте действия в правом верхнем углу</Alert>
                ) : null}

                <Toaster position={Position.BOTTOM} ref={controlToasterRef} usePortal={false} />
            </div>
}

export default Control
