import React, { useState } from 'react'
let types = require('./displayTypes')

const getHiddenSuffix = (k, o, p) => {
    let suffix = ''

    switch(true) {
        case !o && k === 'offside':
        case ['none', 'accuracy'].includes(p) && k.includes('_short'):
        case ['none', 'accuracy'].includes(p) && k.includes('_medium'):
        case ['none', 'accuracy'].includes(p) && k.includes('_far'):
        case p === 'none' && k === 'accurate_total':
            suffix = ' hidden'
            break
    }

    return suffix
}

const Row = ({ home, away, item, passesMode, hasOffsides }) => {
    const tot = home + away
    const hp = tot === 0 ? 50 : Math.ceil((home/tot)*100)
    const ap = 100 - hp

    const hiddenSuffix = getHiddenSuffix(item.key, hasOffsides, passesMode)

    return  <div className={'stats-row'+hiddenSuffix} id={item.key+'StatsRow'}>
                <div className='label'>
                    <span>{item.perc ? hp+'%' : home}</span>
                    <b>{item.statsLabel || item.label}</b>
                    <span>{item.perc ? ap+'%' : away}</span>
                </div>
                <div className='bar'>
                    <div className='bar-inner _home' style={{width: hp+'%'}}></div>
                    <div className='bar-inner _away' style={{width: ap+'%'}}></div>
                </div>
            </div>
}

const Stats = ({ data, hasOffsides, passesMode, mirror }) => {
    return  <div className='panel-item stats'>
                {data && data.home && data.away ? types.filter(t => !t.filter).map(t => (
                    <Row
                        key={`row_${t.key}`}
                        home={!mirror ? parseInt(data.home[t.key]) : parseInt(data.away[t.key])}
                        away={!mirror ? parseInt(data.away[t.key]) : parseInt(data.home[t.key])}
                        item={t}
                        passesMode={passesMode}
                        hasOffsides={true}
                    />
                )) : null}
            </div>
}

export default Stats
