import { useState, useEffect, useRef } from 'react'
import { Switch } from '@blueprintjs/core'

import './roster.scss'

const RosterAlert = ({ roster, side, initial, onSelect, onSubSelect, schema }) => {
    const [height, setHeight] = useState(null)
    const [needRefinement, setNeedRefinement] = useState(initial && initial.needRefinement ? true : false)
    const [player, setPlayer] = useState(initial && initial.player ? {...initial.player} : null)
    const [sub, setSub] = useState(null)
    const [showSubs, setShowSubs] = useState(false)
    const rosterRef = useRef(null)

    useEffect(() => {
        if(rosterRef && rosterRef.current) {
            setHeight(rosterRef.current.offsetWidth)
        }
    }, [rosterRef])

    useEffect(() => {
        onSelect({
            player: player ? {...player} : null,
            needRefinement: needRefinement
        })
    }, [needRefinement, player])

    useEffect(() => {
        if(sub) {
            onSubSelect({...sub})
        }
    }, [sub])

    const lines = schema.split('-')
                    .reduce((acc, _qty) => {
                        const qty = parseInt(_qty)
                        const line = roster.main.slice(acc.idx, (acc.idx+qty))
                        acc.idx += qty
                        acc.rows.push(line)
                        return acc
                    }, {idx: 0, rows: []})

    return [!showSubs ? <div key={'roster'+' '+side} className={'roster'+' '+side} ref={rosterRef} style={{height: height}}>
                {lines.rows.map((line, li) => (
                    <div className="roster-line" key={`line_${li}`}>
                        {line.map(person => (
                            <div
                                className={"subs-item roster-line_person"+(player && (player._id === person._id) ? !onSubSelect ? ' selected' : ' out' : '')}
                                onClick={() => setPlayer({...person})}
                                key={`person_${person._id}`}
                            >
                                <div className='num'>{person.num}</div>
                                <div className='name'>{person.surname}</div>
                            </div>
                        ))}
                    </div>
                ))}
            </div> : (
                <div key={'subs '+side} className={'subs '+side}>
                    <b>Запасные игроки</b>
                    <div className='subs-grid'>
                        {roster.subs.sort((a, b) => parseInt(a.num) - parseInt(b.num)).map(person => (
                            <div
                                className={"subs-item roster-line_person"+(!onSubSelect ? (player && player._id === person._id ? ' selected' : '') : (sub && sub._id === person._id ? ' in' : ''))}
                                onClick={() => !onSubSelect ? setPlayer({...person}) : setSub({...person})}
                                key={`person_${person._id}`}
                            >
                                <div className='num'>{person.num}</div>
                                <div className='name'>{person.surname}</div>
                            </div>
                        ))}
                    </div>
                </div>
            ),
            <div key={'subs-switch '+side} className={'subs-switch '+side} onClick={() => setShowSubs(!showSubs)}>
                <img src={require(`@icons/${showSubs ? 'lineup' : 'bench'}.png`).default} />
            </div>,
            !onSubSelect ? <div className='roster-controls' key='roster-controls'>
                <Switch
                    label='Требует уточнения'
                    large
                    checked={needRefinement}
                    onChange={() => setNeedRefinement(!needRefinement)}
                />
            </div> : <div key='holder' style={{height: 20}}></div>
        ]
}

export default RosterAlert
