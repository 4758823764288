import React, { useState, useEffect, useContext, useRef } from 'react'
import { Tag, Button, ControlGroup, NumericInput, Switch, RadioGroup, Radio } from '@blueprintjs/core'
import { Steps } from 'intro.js-react'
import { useHistory } from 'react-router-dom'
import { dot } from 'dot-object'
import AppContext from '@ctx'
import { resetMatch } from './store'
import axios from 'axios'

import MatchStageDialog from '@components/Dialogs/MatchStageDialog'

import { convertTime, periodPromts } from './utils'

const LeaveMatchDialog = ({ onConfirm, resetMatch }) => {
    const ctx = useContext(AppContext)

    return [<div>
                <p>Матч ещё не завершён! Он будет закрыт и данные перестанут обновляться у заказчика. Делайте так только в экстренных случаях!</p>
            </div>,
            <div className='dialog-actions'>
                <Button intent='primary' text='Отмена' fill={true} onClick={() => ctx.setCtx('dialog', null)} />
                <Button intent='default' style={{marginTop: 10, marginBottom: -20, border: '1px solid #eee'}} text='Выйти из матча' fill={true} onClick={() => onConfirm()} />
            </div>]
}

const ResetStatsDialog = ({ setEntityDialog, resetMatch }) => {
    return [<div>
                <p>Это тестовый матч. Его состояние можно сбросить, чтобы использовать для тренировок. Сделать это сейчас?</p>
            </div>,
            <div className='dialog-actions'>
                <Button intent='primary' text='Да, сбросить' fill={true} onClick={() => resetMatch()} />
                <Button intent='default' style={{marginTop: 10, marginBottom: -20, border: '1px solid #eee'}} text='Отмена' fill={true} onClick={() => setEntityDialog()} />
            </div>]
}

const EntityDialog = ({ data, eid, closeMenu, selfTrigger, resetMatch }) => {
    const [entity, setEntity] = useState(Object.assign({}, data))
    const [progress, setProgress] = useState(false)
    const hasChanged = JSON.stringify(data) !== JSON.stringify(entity)
    const ctx = useContext(AppContext)

    const updateEntity = () => {
        setProgress(true)
        axios.put(`${ctx.rest}stats/${eid}`, dot({entity: entity}))
            .then(upd => {
                setProgress(false)
                if(upd.data && !upd.data.error) {
                    closeMenu()
                    ctx.toaster.current.show({intent: 'success', message: 'Настройки сохранены'})
                    ctx.batchSetCtx({
                        ...ctx,
                        dialog: null,
                        matchData: {...ctx.matchData, entity: entity}
                    })
                } else {
                    ctx.toaster.current.show({intent: 'danger', message: upd.data.error})
                }
            })
    }

    const resetMatchStatsDialog = () => {
        ctx.setCtx('dialog', {
            title: 'Сброс статистики',
            icon: 'reset',
            body: <ResetStatsDialog setEntityDialog={selfTrigger} resetMatch={resetMatch} />
        })
    }

    return  [<div className='form-grid' key='grid'>
                <ControlGroup>
                    <label>Количество таймов:</label>
                    <NumericInput max={4} min={1} value={entity.periods} onValueChange={v => setEntity({...entity, periods: v})} />
                </ControlGroup>
                <ControlGroup>
                    <label>Длительность тайма:</label>
                    <NumericInput min={5} max={45} stepSize={entity.duration > 14 ? 5 : 1} value={entity.duration} onValueChange={v => setEntity({...entity, duration: v})}/>
                </ControlGroup>
                <ControlGroup>
                    <label>Длительность экстра-тайма:</label>
                    <NumericInput min={5} max={15} stepSize={1} value={entity.etDuration} onValueChange={v => setEntity({...entity, etDuration: v})}/>
                </ControlGroup>
                <ControlGroup>
                    <Switch label='Чистое время' checked={entity.pureTime} onChange={() => setEntity({...entity, pureTime: !entity.pureTime})} />
                    <Switch label='Оффсайды' checked={entity.offsides} onChange={() => setEntity({...entity, offsides: !entity.offsides})} />
                </ControlGroup>
                <ControlGroup>
                    <RadioGroup label='Режим фиксации передач' selectedValue={entity.passesMode} onChange={e => setEntity({...entity, passesMode: e.target.value})}>
                        <Radio value='none' label='Не фиксировать' />
                        <Radio value='accuracy' label='Точные передачи' />
                        <Radio value='full' label='Направление передач' />
                    </RadioGroup>
                </ControlGroup>
            </div>,
            <div className='dialog-actions' key='actions'>
                <Button intent='primary' loading={progress} disabled={!hasChanged || progress} text='Сохранить' fill={true} onClick={() => updateEntity()} />
                {!entity.isProduction ? <Button style={{marginTop: 10, marginBottom: -20}} icon='reset' text='Сбросить статистику матча' fill={true} onClick={() => resetMatchStatsDialog()} /> : null}
            </div>
        ]
}

const Top = ({ period,time, onPeriodLaunched, score, matchData, mirror, onSidesReverted, setMatchStats }) => {
    const [menu, setMenu] = useState(false)
    const ctx = useContext(AppContext)
    const history = useHistory()

    const ClubsPlacementDialog = ({ home, away, nextPeriod }) => {
        const [mrr, setMrr] = useState(mirror)

        const h = mrr ? away : home
        const a = mrr ? home : away

        return  <div>
                    <div style={{textAlign: 'center'}}>как команды располагаются на поле относительно вас?</div>
                    <div className='dialog-sides'>
                        <div className='dialog-sides_team'>
                            <label>слева:</label>
                            <div>{h.name}</div>
                            <div className='dialog-sides_emb' style={{backgroundImage: h.emblem ? `url(${h.emblem})` : `url(${matchData.entity.externalBase}img/${h.logoId}.png)`}} />
                        </div>
                        <div className='dialog-sides_revert'>
                            <Button
                                intent='danger'
                                icon='refresh'
                                onClick={() => {
                                    let v = !mrr
                                    onSidesReverted(v)
                                    setMrr(v)
                                }}
                            />
                        </div>
                        <div className='dialog-sides_team'>
                            <label>справа:</label>
                            <div>{a.name}</div>
                            <div className='dialog-sides_emb' style={{backgroundImage: a.emblem ? `url(${a.emblem})` : `url(${matchData.entity.externalBase}img/${a.logoId}.png)`}} />
                        </div>
                    </div>
                    <div className='dialog-actions'>
                        <Button
                            fill={true}
                            large={true}
                            intent='primary'
                            text='Применить расположение'
                            onClick={() => {
                                onPeriodLaunched(nextPeriod)
                                ctx.setCtx('dialog', null)
                                setMenu(false)
                                if(nextPeriod) {
                                    ctx.toaster.current.show({timeout: 1000, intent: 'primary', icon: 'stopwatch', message: 'Таймер матча запущен'})
                                }
                            }}
                        />
                    </div>
                </div>
    }

    const setEntityDialog = () => {
        ctx.setCtx('dialog', {
            title: 'Настройки матча',
            icon: 'cog',
            body: <EntityDialog
                    data={matchData.entity}
                    eid={matchData.entry}
                    closeMenu={() => setMenu(false)}
                    selfTrigger={() => setEntityDialog()}
                    resetMatch={async () => {
                        const _stats = await resetMatch(matchData.entry)
                        console.log('setting reseted', _stats)
                        setMatchStats(_stats)
                        ctx.setCtx('dialog', null)
                        setMenu(false)
                        ctx.toaster.current.show({intent: 'primary', message: 'Статистика матча успешно сброшена', timeout: 3000})
                    }}
                />
        })
    }

    const leaveMatchDialog = () => {
        if(period && period === 'finished') {
            history.push('/matches')
        } else {
            ctx.setCtx('dialog', {
                title: 'Вы уверены?',
                icon: 'warning-sign',
                body: <LeaveMatchDialog onConfirm={() => {window.location.replace('/matches'); ctx.setCtx('dialog', null)}} />
            })
        }
    }

    const displayTime = convertTime(time)

    const renderActionBtn = () => {
        let actionPromt = periodPromts[period]
        const periodsQue = Object.keys(periodPromts)
        const periodIdx = periodsQue.findIndex(p => p === period) === -1 ? 0 : periodsQue.findIndex(p => p === period)

        let obj = {
            icon: !period || period === 'null' || period.includes('f') ? 'play' : 'stop',
            text: !period || period === 'null' || period.includes('f') ? `Начать ${actionPromt}` : `Закончить ${actionPromt}`,
            intent: !period || period === 'null' || period.includes('f') ? 'primary' : 'danger',
            fill: true,
            onClick: () => {
                const next = periodsQue[periodIdx+1]
                setMenu(false)
                if(period &&  !period.includes('f') && period !== 'null') {
                    ctx.setCtx('dialog', {
                        icon: 'notifications',
                        title: 'Что будет дальше?',
                        body: <MatchStageDialog period={period} next={next} onPeriodLaunched={onPeriodLaunched} fromStudy={false} />
                    })
                } else {
                    ctx.setCtx('dialog', {
                        icon: 'stopwatch',
                        title: 'Расположение команд',
                        body: <ClubsPlacementDialog nextPeriod={next} mirror={mirror} home={matchData.home} away={matchData.away}/>,
                        dissmissLocked: true
                    })
                }
            }
        }

        return <Button {...obj} large={true} />
    }

    const stepsRef = useRef(null)

    const hKey = mirror ? 'away' : 'home'
    const aKey = mirror ? 'home' : 'away'

    return  [<div className='top' id='matchtop' key='matchtop'>
                <div className='period'>
                    <Tag minimal={true} large={true} intent='none'>{period ? period !== 'null' && period !== 'finished' ? period.replace('f','') : '-' : '-'}</Tag>
                    <div className='time' id='displayTime'>{displayTime}</div>
                </div>

                <div className='info'>
                    <div className='team'>{matchData ? matchData[hKey].shortName : '-'}</div>
                    <div className='score'>{typeof(score[hKey]) !== 'undefined' ? score[hKey] : '-'}:{typeof(score[aKey]) !== 'undefined' ? score[aKey] : '-'}</div>
                    <div className='team'>{matchData ? matchData[aKey].shortName : '-'}</div>
                </div>

                <div className='actions'>
                    <Button
                        onClick={() => setMenu(!menu)}
                        minimal={true}
                        intent='primary'
                        icon='menu'
                        text='Действия'
                        fill={true}
                        id='timingAction'
                    />
                </div>
            </div>,
            <div className={'menu'+(menu ? ' shown' : '')} id='menu' key='menu'>
                <div>
                    {/*<div>
                        <Button large={true} text='Инфо и настройки' icon='cog' fill={true} onClick={() => setEntityDialog()} />
                    </div>*/}
                    <div style={{opacity: .5}}>
                        <Button large={true} text='Выход из матча' icon='delete' fill={true} onClick={() => leaveMatchDialog()} />
                    </div>
                    <div>
                        <Button large={true} text='Расположение' icon='refresh' fill={true} onClick={() => {
                            ctx.setCtx('dialog', {
                                icon: 'stopwatch',
                                title: 'Расположение команд',
                                body: <ClubsPlacementDialog nextPeriod={null} mirror={mirror} home={matchData.home} away={matchData.away}/>,
                                dissmissLocked: false
                            })
                        }} />
                    </div>
                </div>
                <div style={{width: '100%', marginTop: 6}}>
                    <Button large={true} text='Вернуться к матчу' icon='arrow-left' fill={true} onClick={() => setMenu(false)} />
                </div>
                {period !== 'finished' ? <div className='menu-action'>{renderActionBtn()}</div> : (
                    <div className='menu-action'>
                        <Button intent='danger' large={true} fill={true} text='Закрыть и вернуться к списку матчей' onClick={() => history.push('/matches')} />
                    </div>
                )}
            </div>
        ]
}

export default Top
